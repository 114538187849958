import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';
import WPFIconImage from '../images/icons/wpf_icon.png';

function WPFImage({ size, className }) {
  return <img src={WPFIconImage} width={size} height={size} className={className} alt="WPF icon" />;
}


export default function WPFIcon() {
  return (
    <BaseIcon
      Component={WPFImage}
      title="WPF"
    />
  );
}

WPFImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

WPFImage.defaultProps = {
  className: '',
};
