import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function MahappsSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 225 225" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Mahapps-Icon">
          <circle id="Oval" fill="#3781D7" cx="112.5" cy="112.5" r="112.5" />
          <path d="M96.0042515,82.0015603 C91.0664097,81.9530437 87.0110611,83.0521176 87.0110611,83.0521176 C67.641046,86.8964677 56.6441564,110.535463 46.4343787,109.389328 C39.3256176,109.389328 27.2551261,94.2508368 27.0165864,96.3042839 C26.7777004,98.3816947 28.687057,149.551772 61.8444265,151.844007 C90.2791247,154.136243 112.320854,122.354935 112.320854,122.354935 L112.679183,122.665094 C112.679183,122.665094 134.720913,154.422664 163.155611,152.130255 C196.313673,149.83802 198.221991,98.6918717 197.983451,96.6144608 C197.744565,94.5610137 185.650531,109.699505 178.541943,109.699505 C168.355881,110.845709 157.358991,87.2066447 137.988976,83.3623638 C137.988976,83.3623638 129.401373,81.022305 122.006468,83.3138472 C119.167706,84.4839633 115.923531,85.4150832 112.894007,87.5879677 L112.703591,87.756043 L112.106376,87.3024129 C109.076852,85.105634 105.832677,84.1982526 102.993916,83.0043807 L96.0042515,82.0015603 Z" id="mustache" fill="#FFFFFF" />
        </g>
      </g>
    </svg>
  );
}

export default function MahappsIcon() {
  return (
    <BaseIcon
      Component={MahappsSVG}
      title="Mahapps"
    />
  );
}

MahappsSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

MahappsSVG.defaultProps = {
  className: '',
};
