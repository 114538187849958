import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import CompleteTaskIcon from '../icons/CompleteTaskIcon';
import PartiallyTaskIcon from '../icons/PartiallyTaskIcon';
import IncompleteTaskIcon from '../icons/IncompleteTaskIcon';

function StatusIcon({ status }) {
  const lower = status.toLowerCase();

  switch (lower) {
    case 'complete':
      return <CompleteTaskIcon />;
    case 'partially':
      return <PartiallyTaskIcon />;
    case 'incomplete':
      return <IncompleteTaskIcon />;
    default:
      return <div>{status}</div>;
  }
}

export default function TaskTable({ tasks }) {
  return (
    <Table size="sm">
      <tbody>
        {
            tasks.map((task) => (
              <tr key={task.message}>
                <td><StatusIcon status={task.status} /></td>
                <td style={{ marginTop: 'auto', marginBottom: 'auto' }}>{task.message}</td>
              </tr>
            ))
        }
      </tbody>
    </Table>
  );
}

TaskTable.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
};

TaskTable.defaultProps = {
  tasks: [],
};

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
};
