import React from 'react';
import { DiJqueryLogo } from 'react-icons/di';
import BaseIcon from './BaseIcon';

export default function JqueryIcon() {
  return (
    <BaseIcon
      Component={DiJqueryLogo}
      title="jQuery"
      color="#78CFF5"
    />
  );
}
