import React from 'react';
import { Col, Row } from 'react-bootstrap';
import CenteredCol from '../general/CenteredCol';
import LargeBreak from '../general/LargeBreak';
import PageHeader2 from '../general/PageHeader2';
import ResumeDownloadButton from '../general/ResumeDownloadButton';
import ViewButton from '../general/ViewButton';
import IconList from '../icons/IconList';
import computerImage from '../images/home/dev_computer.png';

export default function Home() {
  return (
    <>
      <h1 className="display-1 text-center">Aaron Sletten</h1>
      <hr />
      <h5 className="text-center font-weight-light">Software Engineer</h5>

      <br />
      <br />
      <Row>
        <Col sm />
        <Col sm={8}>
          <figure className="figure">
            <img src={computerImage} className="figure-img img-fluid rounded" alt="macbook" />
          </figure>
        </Col>
        <Col sm={2} />
      </Row>
      <LargeBreak />
      <Row>
        <Col md={4} xs={12} className="text-center mb-5">
          <h1>Projects</h1>
          <p>These are some of the projects I have created for work, school and just for fun</p>
          <ViewButton href="/projects" />
        </Col>
        <Col md={4} xs={12} className="text-center mb-5">
          <h1>Resume</h1>
          <p>View/download resume</p>
          <br />
          <ResumeDownloadButton className="ml-1" />
        </Col>
        <Col md={4} xs={12} className="text-center mb-5">
          <h1>Contact</h1>
          <p>You can contact me by email anytime and by phone between the hours of 9AM-9PM PST</p>
          <ViewButton href="/contact" />
        </Col>
      </Row>
      <LargeBreak />

      <PageHeader2 title="About this Website" />
      <p>
        This website is built with React and deployed using Vercel.
      </p>

      <Row>
        <CenteredCol>
          <IconList iconNames={['react', 'bootstrap4', 'vercel']} />
        </CenteredCol>
      </Row>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
