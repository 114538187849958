import React from 'react';

export default function LargeBreak() {
  return (
    <>
      <br />
      <br />
      <br />
      <br />
      <hr />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
