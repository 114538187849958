import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import IconList from '../icons/IconList';


export default function ProjectCard({
  title, description, viewPath, iconNames, image,
}) {
  const [border, setBorder] = useState('');
  const navigate = useNavigate();

  return (
    <Card
      onMouseEnter={() => setBorder('secondary')}
      onMouseLeave={() => setBorder('')}
      onClick={() => navigate(viewPath)}
      border={border}
    >
      <Card.Img variant="top" src={image} />
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
      <Card.Footer style={{ display: 'inline-block', textAlign: 'center' }}>
        <IconList iconNames={iconNames} />
      </Card.Footer>
    </Card>
  );
}

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  viewPath: PropTypes.string.isRequired,
  iconNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.string.isRequired,
};
