import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function WindowsServerSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 1939 2500" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="windows" fillRule="nonzero">
          <path d="M546.950178,1875.90373 L184.70444,1875.90373 L184.70444,2000.10189 L546.950178,2000.10189 L546.950178,2106.18505 C546.950178,2124.29631 534.013299,2119.12006 523.658926,2119.12006 L205.401949,2119.12006 C189.878817,2119.12006 182.116315,2121.70631 182.116315,2139.81944 L182.116315,2243.32197 L518.486421,2243.32197 C539.18393,2243.32197 544.36018,2251.08447 544.36018,2269.19386 L544.36018,2416.67578 C684.079604,2437.38078 821.214648,2458.07455 960.934071,2476.18768 L960.934071,2481.36393 L32.0425183,2481.36393 C0.992509045,2481.36393 0.992509045,2481.36393 0.992509045,2450.31392 L0.992509045,139.717551 C0.992509045,46.5712684 47.5693957,0 140.717551,0 L1072.1991,0 C1142.06162,0 1188.63664,31.0500093 1204.15977,85.3893982 C1209.33789,98.3244051 1209.33789,113.847537 1209.33789,129.374414 L1209.33789,1135.89099 C1209.33789,1159.18224 1198.98726,1161.76475 1180.87413,1164.351 L559.885185,1257.49916 C541.773928,1260.08916 541.773928,1273.02603 541.773928,1285.96104 L541.773928,1839.67934 C546.950178,1850.03184 546.950178,1860.37685 546.950178,1875.90373 Z M184.706312,483.852032 L1030.80221,483.852032 L1030.80221,362.243866 L184.706312,362.243866 L184.706312,483.852032 Z" id="Shape" fill="#00ABEC" />
          <path d="M1217.09852,1813.80933 C1217.09852,1803.45683 1214.51414,1795.69245 1214.51414,1787.93369 L1214.51414,1317.01667 C1214.51414,1296.31541 1219.69039,1288.55291 1240.38603,1285.96666 C1447.37985,1260.0929 1651.79116,1231.62727 1858.78685,1203.16726 C1884.65874,1200.57726 1907.94812,1195.40288 1936.41187,1192.8185 C1936.41187,1203.16726 1938.99813,1213.51601 1938.99813,1223.86851 L1938.99813,1782.75932 C1938.99813,1808.63308 1933.82,1813.80933 1907.94812,1813.80933 L1242.97228,1813.80933 C1237.79978,1813.80933 1227.44915,1813.80933 1217.09852,1813.80933 L1217.09852,1813.80933 Z M1217.09852,2395.98015 L1217.09852,1883.6681 L1939,1883.6681 L1939,2499.4808 C1698.3643,2465.84079 1457.73422,2429.61453 1217.09852,2395.98015 L1217.09852,2395.98015 Z M1152.41412,1301.48979 L1152.41412,1813.80558 C1144.65162,1813.80558 1136.88725,1816.39183 1129.12474,1816.39183 L697.022102,1816.39183 C678.910845,1816.39183 668.56209,1811.21558 668.56209,1790.51995 L668.56209,1371.35419 C668.56209,1371.35419 671.148343,1366.17794 689.261472,1363.59168 C839.335269,1342.88668 991.989699,1322.19292 1142.0635,1301.48979 L1152.41412,1301.48979 L1152.41412,1301.48979 Z M1152.41412,2385.62952 C1035.97846,2367.51639 919.544671,2351.99513 805.699007,2333.882 C766.880877,2328.70575 730.663982,2320.94512 691.847724,2318.35887 C678.910845,2315.76887 673.736468,2313.18262 673.736468,2300.24574 L673.736468,1894.01686 C673.736468,1891.42686 673.736468,1888.84061 676.326465,1886.25248 L1155.00225,1886.25248 C1152.41412,2049.26316 1152.41412,2217.44447 1152.41412,2385.62952 Z" id="Shape" fill="#1B348E" />
        </g>
      </g>
    </svg>
  );
}

export default function WindowsServerIcon() {
  return (
    <BaseIcon
      Component={WindowsServerSVG}
      title="Windows Server 2012"
    />
  );
}

WindowsServerSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

WindowsServerSVG.defaultProps = {
  className: '',
};
