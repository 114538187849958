import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function ArduinoSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 722 344" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Arduino_Logo" transform="translate(30.000000, 14.000000)" stroke="#00979C">
          <path d="M146,17 C50.937,9.427 -28.94153,121.8 10.361,208.95 C48.313,303.35 191.35,329.08 259.52,253.37 C325.4,188.82 350.85,89.8 431.04,38.741 C513.07,-17.2196 645.25,35.616 660.79,135.17 C676.41,230.5 573.73,323.36 480.52,295.1 C411.81,279.57 362.37,220.95 334.24,159.33 C299.72,100.36 254.59,37.526 184.19,20.945 C171.68,18.108 158.82,16.987 146,17 Z" id="infinity" strokeWidth="59" />
          <line x1="90" y1="152" x2="210" y2="152" id="minus" strokeWidth="25" />
          <path d="M458,152 L558,152 M507,102 L507,202" id="plus" strokeWidth="32" />
          <path d="M648.41,0.375 C648.41,1.156 648.41,1.938 648.41,2.719 C650.13,2.719 651.84,2.719 653.56,2.719 C653.56,7.24 653.56,11.76 653.56,16.281 C654.45,16.281 655.33,16.281 656.22,16.281 C656.22,11.76 656.22,7.24 656.22,2.719 C657.95,2.719 659.68,2.719 661.41,2.719 C661.41,1.938 661.41,1.156 661.41,0.375 C657.08,0.375 652.74,0.375 648.41,0.375 Z M663.19,0.375 C663.19,5.677 663.19,10.979 663.19,16.281 C664,16.281 664.81,16.281 665.62,16.281 C665.62,11.76 665.62,7.24 665.62,2.719 C667.05,7.24 668.48,11.76 669.91,16.281 C670.71,16.281 671.51,16.281 672.31,16.281 C673.68,11.834 675.04,7.385 676.41,2.938 C676.41,7.386 676.41,11.833 676.41,16.281 C677.21,16.281 678.01,16.281 678.81,16.281 C678.81,10.979 678.81,5.677 678.81,0.375 C677.54,0.375 676.27,0.375 675,0.375 C673.7,4.616 672.39,8.853 671.09,13.094 C669.76,8.853 668.42,4.616 667.09,0.375 C665.79,0.375 664.49,0.375 663.19,0.375 Z" id="TM" fill="#00979C" fillRule="nonzero" />
        </g>
      </g>
    </svg>
  );
}

export default function ArduinoIcon() {
  return (
    <BaseIcon
      Component={ArduinoSVG}
      title="Arduino"
    />
  );
}

ArduinoSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

ArduinoSVG.defaultProps = {
  className: '',
};
