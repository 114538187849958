import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from './BaseIcon';


function DjangoSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 261418 333333" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd">
      <path d="M117587 0h54579v250154c-27956 5288-48537 7369-70805 7369C34700 257453 0 227673 0 170476c0-55108 36807-90874 93845-90874 8851 0 15594 697 23742 2783V0zm143831 86317v125248c0 43133-3231 63876-12714 81759-8851 17193-20511 28034-44604 40008l-50645-23866c24093-11197 35753-21091 43200-36189 7796-15440 10255-33324 10255-80361V86317h54509v-1zM201409 0h54579v55457h-54579V0zm-81909 127465c-6392-2087-11660-2783-18404-2783-27605 0-43550 16838-43550 46348 0 28731 15243 44595 43200 44595 6040 0 10957-340 18754-1384v-86776z" fill="#2ba977" />
    </svg>
  );
}


export default function DjangoIcon() {
  return (
    <BaseIcon
      Component={DjangoSVG}
      title="Django"
    />
  );
}

DjangoSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

DjangoSVG.defaultProps = {
  className: '',
};
