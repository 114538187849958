import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function VisualStudioSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 93 93" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M32.02754,54.985 L69.69694,92.2498 L92.24874,82.6558 L92.24874,9.594 L69.69694,0 L32.02754,37.2688 L9.3446,20.1718 L-5.68434189e-14,23.3002 L-5.68434189e-14,68.9536 L9.3446,72.082 L32.02754,54.985 Z M69.69694,26.5926 L69.69694,65.6656 L43.77894,46.129 L69.69694,26.5926 Z M9.3446,32.5456 L23.07234,46.1248 L9.3446,59.7082 L9.3446,32.5456 Z" id="Visual-Studio-2017-Icon" fill="#865FC5" fillRule="nonzero" />
      </g>
    </svg>
  );
}

export default function VisualStudioIcon() {
  return (
    <BaseIcon
      Component={VisualStudioSVG}
      title="Visual Studio 2017"
    />
  );
}

VisualStudioSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

VisualStudioSVG.defaultProps = {
  className: '',
};
