import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import TableBaseIcon from './TableBaseIcon';

export default function IncompleteTaskIcon() {
  return (
    <TableBaseIcon
      Component={FaTimesCircle}
      title="Incomplete"
      color="red"
    />
  );
}
