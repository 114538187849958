import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function NGINXSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 175 199" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="NGINX-Icon" fillRule="nonzero">
          <path d="M1.3531,148.69127 C2.249146,150.251114 3.54879931,151.540809 5.11549,152.42483 L82.08349,196.86235 L82.08589,196.85995 C85.2436986,198.71471 89.1581314,198.71471 92.31594,196.85995 L169.28637,152.42243 C172.470424,150.616063 174.426826,147.227284 174.399273,143.56663 L174.399273,54.69163 L174.39658,54.68923 C174.424114,51.0274069 172.466799,47.6377176 169.28158,45.83104 L92.31116,1.39348 L92.31116,1.39108 C89.1559329,-0.463694334 85.2435371,-0.463694334 82.08831,1.39108 L82.08831,1.39348 L5.1203,45.831 C1.9334732,47.6363725 -0.0250906151,51.0266153 0.00287,54.68919 L6.58677816e-06,54.69159 L6.58677816e-06,143.56659 C-0.0168112811,145.364935 0.449529305,147.134877 1.3507,148.69123" id="Path" fill="#009639" />
          <path d="M62.55632,132.96059 C62.5563198,138.42738 58.1246105,142.85909 52.65782,142.85909 C47.1910295,142.85909 42.75932,138.42738 42.75932,132.96059 L42.76172,132.96299 L42.77133,65.01413 C42.77133,59.75013 47.42267,55.39674 54.01045,55.39674 C58.72665,55.39674 64.17082,57.29956 67.50316,61.42953 L70.49433,65.00933 L111.847,114.497 L111.847,65.29043 L111.8422,65.29043 C111.8422,61.7540365 113.728843,58.486274 116.79145,56.7180772 C119.854056,54.9498804 123.627344,54.9498804 126.68995,56.7180772 C129.752557,58.486274 131.6392,61.7540365 131.6392,65.29043 L131.6368,65.29043 L131.6272,133.23443 C131.6272,138.49843 126.97346,142.85183 120.38808,142.85183 C115.66947,142.85183 110.2277,140.95141 106.89537,136.81903 L62.55152,83.7516 L62.55152,132.963 L62.55632,132.96059 Z" id="Path" fill="#FFFFFF" />
        </g>
      </g>
    </svg>
  );
}

export default function NGINXIcon() {
  return (
    <BaseIcon
      Component={NGINXSVG}
      title="NGINX"
    />
  );
}

NGINXSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

NGINXSVG.defaultProps = {
  className: '',
};
