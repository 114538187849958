import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

export default function PageTitle({ title }) {
  return (
    <Row>
      <Col>
        <h1 className="display-3 text-center">{ title }</h1>
        <hr />
      </Col>
    </Row>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
