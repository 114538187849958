import React from 'react';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import TaskTable from '../general/TaskTable';
import Figures from '../general/Figures';
import CenteredIconList from '../icons/CenteredIconList';

import detailViewImage from '../images/projects/2018_lab_monitor/01_details_view.png';
import graphViewImage from '../images/projects/2018_lab_monitor/02_graph_view.png';
import profileNoLoopsImage from '../images/projects/2018_lab_monitor/03_profile_no_loops.png';
import profileWithLoopsImage from '../images/projects/2018_lab_monitor/04_profile_with_loops.png';
import snapshotPageImage from '../images/projects/2018_lab_monitor/05_snapshot_page.png';
import snapshotGraphImage from '../images/projects/2018_lab_monitor/06_snapshot_graph.png';
import logViewImage from '../images/projects/2018_lab_monitor/07_log_view.png';
import machineLogImage from '../images/projects/2018_lab_monitor/08_machine_log.jpg';

const tasks = [
  {
    message: 'View all real time machine data',
    status: 'complete',
  },
  {
    message: 'Log all machine data',
    status: 'complete',
  },
  {
    message: 'View all machine logs',
    status: 'complete',
  },
  {
    message: 'Download all machine logs',
    status: 'complete',
  },
  {
    message: 'Retrieve all machine profiles',
    status: 'complete',
  },
  {
    message: 'View all machine profiles',
    status: 'complete',
  },
  {
    message: 'Save images of data graphs',
    status: 'complete',
  },
  {
    message: 'View lab temperature and humidity',
    status: 'complete',
  },
];


export default function LabMonitorPage() {
  return (
    <>
      <PageTitle title="Lab Monitor" />
      <PageHeader2 title="Summary" />
      <p>
        The lab monitor website is a tool for monitoring environmental chambers at Appareo. With
        this tool the user can view real time data, "snapshot" data, machine logs and profiles.
        Each page of the application is separated into two main parts. The first part is the graph
        which displays the appropriate data and comes equipped with a context menu to perform data
        manipulation.
      </p>
      <p>
        The second part is the navigation and details view. The navigation allows other logs or
        profiles to be selected. The details view gives the user information pertaining to the set
        of data that is being viewed.
      </p>

      <Figures figures={[
        {
          message: 'Detail view',
          image: detailViewImage,
        },
        {
          message: 'Graph view',
          image: graphViewImage,
        },
      ]}
      />


      <PageHeader2 title="Project Requirements" />
      <TaskTable tasks={tasks} />

      <PageHeader2 title="Back-end Implementation" />
      <p>
        The monitor tool is implemented using a combination of NodeJS and Mongo DB at its core.
        The application is deployed using Express and Nginx.
      </p>
      <CenteredIconList iconNames={['node', 'linux', 'mongodb', 'nginx', 'handlebars']} />

      <PageHeader2 title="Front-end Implementation" />
      <p>
        The client side of the application is written in JavaScript and uses Bootstrap 3 and
        Chart.js for the main viewing experience. Other notable libraries for data parsing and
        manipulation include Moment.js and jQuery.
      </p>
      <CenteredIconList iconNames={['js', 'html', 'css', 'chartjs', 'momentjs', 'jquery', 'bootstrap3']} />

      <PageHeader2 title="Profiles" />
      <p>
        Profiles are ordered arrays of set-points and thresholds distributed over time. The
        profiles are meant to be used to cycle hardware products through different environmental
        conditions. Profiles are stored differently on the machine, depending on the
        machine&apos;s manufacture, function, or communication protocol. Because of the variety
        of profile implementations, the machines were modeled in code as a hierarchy of classes,
        inheriting from one another. The base class is the &quot;device&quot; class. The device
        class has three descendants, based on communication protocols: &quot;GPIBDevice&quot;,
        &quot;ModbusDevice&quot;, and &quot;WebDevice.&quot; Each one of the device subclasses has
        individual machines, or, in some cases, a machine type, if the class can accommodate
        multiple devices.
      </p>


      <Figures figures={[
        {
          message: 'Profile without loops enabled',
          image: profileNoLoopsImage,
        },
        {
          message: 'Profile with loops enabled',
          image: profileWithLoopsImage,
        },
      ]}
      />

      <PageHeader2 title="Real Time Data" />
      <p>
        Real time data acquisition takes advantage of the device classes talked about in the
        profiles section. On server startup, each base device connects to its designated machine
        using a (NodeJS) net TCP socket. Then, at the next level of inheritance (dealing with
        communication protocols), a basic data acquisition method is implemented. After the method
        is implemented, the subclasses can inherit or override it if needed.
      </p>

      <Figures figures={[
        {
          message: 'Snapshot page',
          image: snapshotPageImage,
        },
        {
          message: 'Lab (Temperature, Humidity and Dew point) snapshot graph',
          image: snapshotGraphImage,
        },
      ]}
      />

      <PageHeader2 title="Logs" />
      <p>
        There are two different sets of logs utilized in the application. The first set is
        comprised of the "snapshot logs." The data points for these logs are collected every ten
        seconds and stored in a NoSQL database.
      </p>
      <p>
        The database is then set up to purge any data that was added over 24 hours ago. The next
        set of logs are captured when a profile is "running" on a machine. These logs are stored
        in a csv file format on a networked drive. When a user wants to access a log, the csv file
        is parsed and then sent to the client as JSON objects. The csv can be downloaded directly
        from the tool as well.
      </p>
      <p className="font-weight-light font-italic" style={{ paddingLeft: '40px' }}>
        Note: NoSQL was chosen because of the vast number of data points that were originally going
        to be used, potentially hundreds of millions. Another reason NoSQL was chosen was because
        of object storage flexibility. Flexibility is important because the data point object could
        potentially change in the future.
      </p>

      <Figures figures={[
        {
          message: 'Application graph view of logged data',
          image: logViewImage,
        },
        {
          message: 'Machine graph view of logged data',
          image: machineLogImage,
        },
      ]}
      />
    </>
  );
}
