import React from 'react';
import PropTypes from 'prop-types';

export default function PageHeader2({ title }) {
  return (
    <>
      <br />
      <h3>{title}</h3>
    </>
  );
}

PageHeader2.propTypes = {
  title: PropTypes.string.isRequired,
};
