import React from 'react';
import PropTypes from 'prop-types';
import { Figure, Row, Col } from 'react-bootstrap';
import CenteredCol from './CenteredCol';


export default function Figures({ figures }) {
  if (figures.length === 1) {
    const [figure] = figures;

    let colNum = 0;

    switch (figure.size) {
      case 'xl':
      case 'extralarge':
        colNum = 0;
        break;
      case 'lg':
      case 'large':
        colNum = 1;
        break;
      case 'md':
      case 'medium':
        colNum = 2;
        break;
      case 'sm':
      case 'small':
        colNum = 3;
        break;
      case 'xs':
      case 'extrasmall':
        colNum = 4;
        break;
      default:
        break;
    }

    if (colNum === 0) {
      return (
        <Row>
          <CenteredCol>
            <Figure>
              <Figure.Image alt={figure.message} src={figure.image} />
              <Figure.Caption>{figure.message}</Figure.Caption>
            </Figure>
          </CenteredCol>
        </Row>
      );
    }

    return (
      <Row>
        <Col sm={colNum} />
        <CenteredCol>
          <Figure>
            <Figure.Image alt={figure.message} src={figure.image} />
            <Figure.Caption>{figure.message}</Figure.Caption>
          </Figure>
        </CenteredCol>
        <Col sm={colNum} />
      </Row>
    );
  }

  return (
    <Row>
      {figures.map((figure) => (
        <CenteredCol key={figure.image}>
          <Figure>
            <Figure.Image alt={figure.message} src={figure.image} />
            <Figure.Caption>{figure.message}</Figure.Caption>
          </Figure>
        </CenteredCol>
      ))}
    </Row>
  );
}

Figures.propTypes = {
  figures: PropTypes.arrayOf(PropTypes.shape({})),
};

Figures.defaultProps = {
  figures: [],
};
