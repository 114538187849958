import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


export default function ViewButton({ href }) {
  return (
    <Link to={href}>
      <Button>View</Button>
    </Link>
  );
}

ViewButton.propTypes = {
  href: PropTypes.string.isRequired,
};
