import React from 'react';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import Figures from '../general/Figures';
import CenteredIconList from '../icons/CenteredIconList';

import image01 from '../images/projects/2018_computer_controlled_toy_car/01_controller_switches.jpg';
import image02 from '../images/projects/2018_computer_controlled_toy_car/02_controller_wiring.png';
import image03 from '../images/projects/2018_computer_controlled_toy_car/03_controller_port.jpg';
import image04 from '../images/projects/2018_computer_controlled_toy_car/04_controller_arduino.jpg';
import image05 from '../images/projects/2018_computer_controlled_toy_car/05_car_control.gif';


export default function ComputerControlledCarPage() {
  return (
    <>
      <PageTitle title="Computer Controlled Car" />
      <PageHeader2 title="Summary" />
      <p>
        In this project, I modified the controller of a cheap RC car that my brother bought me for
        Christmas.
      </p>
      <CenteredIconList iconNames={['arduino', 'unity', 'c', 'csharp']} />

      <PageHeader2 title="Electronics" />
      <p>
        The controls on the car controller were just simple switches as opposed to more expensive
        potentiometers, making the electronics side of the project extremely simple. I just
        soldered the wires from each switch to an Arduino digital IO pin and ground, allowing me to
        program the Arduino to switch the digital pins from high to low to move the car.
      </p>
      <Figures figures={[{ image: image01 }, { image: image02 }]} />

      <PageHeader2 title="Construction" />
      <p>
        To fit an Arduino into the car controller, it had to be small. I used an Arduino Nano to
        meet the size requirements. However, the Arduino Nano was still a little too big, so the
        battery case had to be ground down a little for a snug fit. Since the batteries had to be
        removed for this project, I decided to power the controller and Arduino using a broken
        Lightning cable. I cut the damaged Lightning connector off and soldered the +5 volts and
        ground to the Arduino.
      </p>
      <p>
        After I had power working, I routed the switch control wires through a hole in the battery
        compartment and soldered them to the Arduino. After the code was tested and everything
        worked properly, the Arduino was secured in place with hot glue.
      </p>
      <Figures figures={[{ image: image03 }, { image: image04 }]} />

      <PageHeader2 title="Unity" />
      <p>
        The unity program borrowed some code from my quadcopter project to communicate with the
        Arduino imbedded in the controller. The program connected to the car controller and
        provided virtual joysticks or arrow keys to control the car. This setup also allowed for
        custom routines to be run just by clicking the corresponding button in the user interface.
      </p>
      <Figures figures={[{ image: image05, size: 'sm' }]} />
    </>
  );
}
