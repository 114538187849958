import React from 'react';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import TaskTable from '../general/TaskTable';
import Figures from '../general/Figures';
import Footnote from '../general/Footnote';

import centroidDiagramImage from '../images/projects/2016_centroid_calculator/01_centroid_diagram.png';
import nevadaTopPathDrawImage from '../images/projects/2016_centroid_calculator/02_nevada_top_path.png';
import nevadaTopPathDrawGIF from '../images/projects/2016_centroid_calculator/03_nevada_top_path_draw.gif';
import applicationBeforeCalcImage from '../images/projects/2016_centroid_calculator/04_application_before_calc.png';
import applicationAfterCalcImage from '../images/projects/2016_centroid_calculator/05_application_after_calc.png';
import noCorrespondingPointImage from '../images/projects/2016_centroid_calculator/06_no_corresponding_point.png';
import approximatedPoint from '../images/projects/2016_centroid_calculator/07_approximated_point.png';


const tasks = [
  {
    message: 'Find the centroid of each continental state',
    status: 'complete',
  },
  {
    message: 'Find the centroid of the continental US',
    status: 'complete',
  },
];


export default function CentroidCalculatorPage() {
  return (
    <>
      <PageTitle title="Centroid Calculator" />
      <PageHeader2 title="Summary" />
      <p>
        The Centroid Calculator application was built for my Calculus II course at NDSU. The
        objective was to calculate the centroid of all 48 continental states and then calculate the
        centroid of the continental US. I solved this problem using KML files from Google Earth and
        a custom Java program.
      </p>

      <Footnote
        footnoteTitle="Definition"
        footnote="In mathematics and physics, the centroid or geometric center of a plane figure is the
        arithmetic mean position of all the points in the figure. Informally, it is the point at
        which a cutout of the shape could be perfectly balanced on the tip of a pin."
        citation="Wikipedia"
      />

      <Figures figures={[{ image: centroidDiagramImage, size: 'xs' }]} />


      <PageHeader2 title="Project Requirements" />
      <TaskTable tasks={tasks} />


      <PageHeader2 title="Google Earth" />
      <p>
        In Google Earth, one can use their mouse to draw out paths to then be exported as KML
        files. So, to get the data for all 48 continental states, I wrote my group mates
        instructions on how to draw out these paths and create the files. After I compiled the
        files from my groups members, I used the Java program to parse out all the data.
      </p>

      <Figures figures={[{ image: nevadaTopPathDrawImage }, { image: nevadaTopPathDrawGIF }]} />

      <PageHeader2 title="Java" />
      <p>
        The Java application required four different files for each side of the state. The paths to
        these files were entered into a text field, and then the corresponding button to indicate
        which side of the state was being entered was pressed. When the corresponding buttons were
        pressed, the file was parsed, and the raw coordinates were displayed. Once all four files
        were in the program, the calculate button was pressed. When the calculate button is
        pressed, the necessary algorithms are run and the output is displayed.
      </p>

      <Figures figures={[
        { image: applicationBeforeCalcImage },
        { image: applicationAfterCalcImage }]}
      />

      <PageHeader2 title="Math" />
      <p>
        The algorithms that are run in the Java program use Simpson&apos;s rule to perform
        numerical integration on the data points. However, the data points don&apos;t perfectly
        line up with each other, and a path may have more data points than the corresponding path.
      </p>


      <Footnote
        footnoteTitle="Definition"
        footnote="Simpson&apos;s rule is a method for numerical integration, the numerical approximation of
        definite integrals."
        citation="Wikipedia"
      />

      <p>
        The algorithm accounts for the point alignment problem by using the closest corresponding
        point on the x axis to approximate the point directly in line.
      </p>

      <Figures figures={[{ image: noCorrespondingPointImage }, { image: approximatedPoint }]} />
    </>
  );
}
