import React from 'react';
import CenteredIconList from '../icons/CenteredIconList';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import TaskTable from '../general/TaskTable';
import Figures from '../general/Figures';

import internalDatabaseToolCardImage from '../images/projects/2018_database_tool/00_internal_database_tool_card.png';
import windowImage from '../images/projects/2018_database_tool/01_window.PNG';
import windowCollapsedImage from '../images/projects/2018_database_tool/02_window_collapsed.PNG';
import sendToContextMenuImage from '../images/projects/2018_database_tool/03_sendto_context_menu.PNG';


const tasks = [
  {
    message: 'Zip files and folders',
    status: 'complete',
  },
  {
    message: 'Submit zip files to networked drive',
    status: 'complete',
  },
  {
    message: 'Gather user data',
    status: 'complete',
  },
  {
    message: 'Allow users to select project',
    status: 'complete',
  },
  {
    message: 'Populate other fields based on project',
    status: 'complete',
  },
  {
    message: 'Submit data to database',
    status: 'complete',
  },
];

export default function InternalDatabaseToolPage() {
  return (
    <>
      <PageTitle title="Internal Database Tool" />
      <PageHeader2 title="Summary" />
      <p>
        At appareo the test team often needs to keep track of versions folders and files over time. The database tool
        allows a user to choose an internal project folder and submit a zipped file and complimentary data to the database.
      </p>

      <Figures figures={[{ image: internalDatabaseToolCardImage, size: 'md' }]} />


      <PageHeader2 title="Project Requirements" />
      <TaskTable tasks={tasks} />

      <PageHeader2 title="Windows Desktop" />
      <p>
        The client program was written in Visual Studio 2017 using the windows WPF frameworks and C#. The programs job is to
        get the folder/file a user clicks on (using the Windows “Send to” option), zip it, gather some user data, and send
        it to the database.
      </p>

      <CenteredIconList iconNames={['visualstudio', 'wpf', 'csharp', 'mahapps']} />

      <p>
        To gather user data, the Model-View-ViewModel pattern was used to bind data from the user interface to the
        underlying code. The data was then wrapped in a class and sent to the mySQL database using a custom REST service.
        However, the transfer of the zip file wasn't quite as simple.
      </p>

      <Figures figures={[{ image: windowImage }]} />

      <PageHeader2 title="Windows Server" />

      <p>
        Because of the way networked drives and folder permissions work in Windows, I wasn't able to simply transfer the
        files to the proper folder over the network. To get around this issue, I set up a different folder and wrote a
        Windows service that runs on Appareo’s server. The service checks the folder that the program has permission to and
        then moves zip files to the proper, protected folder.
      </p>

      <CenteredIconList iconNames={['mysql', 'windowsserver']} />

      <Figures figures={[
        {
          message: 'Collapsed view for certain projects',
          image: windowCollapsedImage,
        },
        {
          message: 'Windows Send to context menu',
          image: sendToContextMenuImage,
        },
      ]}
      />
    </>
  );
}
