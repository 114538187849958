import React from 'react';
import { FaBluetoothB } from 'react-icons/fa';
import BaseIcon from './BaseIcon';

export default function BluetoothLEIcon() {
  return (
    <BaseIcon
      Component={FaBluetoothB}
      title="Bluetooth LE"
    />
  );
}
