import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from './BaseIcon';


function VercelSVG({ size, className }) {
  return (
    <svg fill="#000000" width={size} height={size} className={className} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M256,48,496,464H16Z" /></svg>
  );
}

export default function VercelIcon() {
  return (
    <BaseIcon
      Component={VercelSVG}
      title="Vercel"
    />
  );
}

VercelSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

VercelSVG.defaultProps = {
  className: '',
};
