import React from 'react';
import { FaMinusCircle } from 'react-icons/fa';
import TableBaseIcon from './TableBaseIcon';

export default function PartiallyTaskIcon() {
  return (
    <TableBaseIcon
      Component={FaMinusCircle}
      title="Partially Complete"
      color="orange"
    />
  );
}
