import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function SwiftSVG({ size, className }) {
  return (
    <svg
      width={`${size}px`}
      className={className}
      viewBox="0 0 1000 900"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-swift">
          <stop stopColor="#F88A36" offset="0%" />
          <stop stopColor="#FD2020" offset="100%" />
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Swift_logo" fill="url(#linearGradient-swift)" fillRule="nonzero">
          <path d="M747.125,826.175 C630.45,893.575 470.025,900.5 308.625,831.325 C177.9425,775.725 69.51,678.4 4.4408921e-14,567.2 C33.365,595 72.29,617.25 113.995,636.7 C280.675,714.825 447.32,709.475 564.595,636.9 C564.52,636.825 564.47,636.775 564.42,636.7 C397.595,508.8 255.795,341.975 150.145,205.75 C127.8925,183.505 111.21,155.7 94.5275,130.6775 C222.425,247.4525 425.4025,394.8025 497.6775,436.5275 C344.7775,275.2525 208.5275,75.0775 214.0775,80.6275 C455.9775,325.3025 681.2025,464.3275 681.2025,464.3275 C688.6525,468.5275 694.4025,472.0275 699.0275,475.1525 C703.9025,462.7525 708.1775,449.8775 711.7775,436.5275 C750.7025,294.7275 706.2275,133.4525 608.9025,0.0025 C834.1025,136.24 967.5775,392.0275 911.9525,606.1275 C910.5025,611.9025 908.9275,617.6025 907.2275,623.2025 C907.8775,623.9775 908.5275,624.7775 909.1775,625.6025 C1020.3775,764.6275 989.8025,911.9775 975.9025,884.1775 C915.5775,766.1275 803.9025,802.2275 747.1275,826.1775 L747.125,826.175 Z" id="Path" />
        </g>
      </g>
    </svg>
  );
}

export default function SwitfIcon() {
  return (
    <BaseIcon
      Component={SwiftSVG}
      title="Swift"
    />
  );
}

SwiftSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

SwiftSVG.defaultProps = {
  className: '',
};
