import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function BaseIcon({
  Component, title, size, color,
}) {
  if (!title) {
    return <Component size={size} className="m-2" style={{ color }} />;
  }
  return (
    <OverlayTrigger
      placement="top"
      overlay={(<Tooltip>{title}</Tooltip>)}
    >
      <span>
        <Component size={size} className="m-2" style={{ color }} />
      </span>
    </OverlayTrigger>
  );
}

BaseIcon.propTypes = {
  Component: PropTypes.elementType,
  title: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
};

BaseIcon.defaultProps = {
  Component: null,
  size: 35,
  color: 'black',
  title: null,
};
