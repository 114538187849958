import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from '../BaseIcon';
import rockImage from '../../images/projects/2015_geology_game/07_rock.png';

function RockImage({ size, className }) {
  return <img src={rockImage} height={size} className={className} alt="rock icon" />;
}


export default function RockIcon() {
  return (
    <BaseIcon
      Component={RockImage}
    />
  );
}

RockImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

RockImage.defaultProps = {
  className: '',
};
