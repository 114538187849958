import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import IconList from './IconList';
import CenteredCol from '../general/CenteredCol';


export default function CenteredIconList({ iconNames }) {
  return (
    <>
      <Row>
        <CenteredCol>
          <IconList iconNames={iconNames} />
        </CenteredCol>
      </Row>
      <br />
    </>
  );
}


IconList.propTypes = {
  iconNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};
