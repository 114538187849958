import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function SpriteKitSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 1042 1086" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="SpriteKit-Icon">
          <g id="Stack">
            <g id="First" transform="translate(0.000000, 286.704322)" fill="#734B8D">
              <path d="M108.758001,315.805002 L417.463418,79.2395545 C478.472272,32.4875827 563.261432,32.4875827 624.270286,79.2395545 L932.975703,315.805002 C979.004681,351.077677 987.724387,416.985688 952.451713,463.014666 C946.843046,470.33368 940.294717,476.882008 932.975703,482.490675 L624.270286,719.056123 C563.261432,765.808095 478.472272,765.808095 417.463418,719.056123 L108.758001,482.490675 C62.729023,447.218001 54.0093165,381.30999 89.281991,335.281012 C94.8906582,327.961997 101.438986,321.413669 108.758001,315.805002 Z" id="Path" />
            </g>
            <g id="Second" transform="translate(0.000000, 146.016699)" fill="#9867AE">
              <path d="M108.758001,315.805002 L417.463418,79.2395545 C478.472272,32.4875827 563.261432,32.4875827 624.270286,79.2395545 L932.975703,315.805002 C979.004681,351.077677 987.724387,416.985688 952.451713,463.014666 C946.843046,470.33368 940.294717,476.882008 932.975703,482.490675 L624.270286,719.056123 C563.261432,765.808095 478.472272,765.808095 417.463418,719.056123 L108.758001,482.490675 C62.729023,447.218001 54.0093165,381.30999 89.281991,335.281012 C94.8906582,327.961997 101.438986,321.413669 108.758001,315.805002 Z" id="Path" />
            </g>
            <g id="Third" fill="#FFFFFF">
              <path d="M108.758001,315.805002 L417.463418,79.2395545 C478.472272,32.4875827 563.261432,32.4875827 624.270286,79.2395545 L932.975703,315.805002 C979.004681,351.077677 987.724387,416.985688 952.451713,463.014666 C946.843046,470.33368 940.294717,476.882008 932.975703,482.490675 L624.270286,719.056123 C563.261432,765.808095 478.472272,765.808095 417.463418,719.056123 L108.758001,482.490675 C62.729023,447.218001 54.0093165,381.30999 89.281991,335.281012 C94.8906582,327.961997 101.438986,321.413669 108.758001,315.805002 Z" id="Path" />
            </g>
          </g>
          <g id="Bubbles-Icon" transform="translate(248.000000, 191.000000)">
            <ellipse id="Oval" fill="#FFBE00" cx="274.253076" cy="281.317383" rx="184.5" ry="144.5" />
            <ellipse id="Oval" fill="#F40300" cx="365.253076" cy="103.317383" rx="131.5" ry="103" />
            <ellipse id="Oval" fill="#26CBC2" cx="105.253076" cy="86.8173826" rx="87.5" ry="68.5" />
            <ellipse id="Oval" fill="#9734D1" cx="62.7530761" cy="242.817383" rx="62" ry="48.5" />
            <ellipse id="Oval" fill="#00C2B8" cx="551.753076" cy="219.817383" rx="57" ry="44.5" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function SpriteKitIcon() {
  return (
    <BaseIcon
      Component={SpriteKitSVG}
      title="SpriteKit"
    />
  );
}

SpriteKitSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

SpriteKitSVG.defaultProps = {
  className: '',
};
