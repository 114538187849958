import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function BootstrapSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 612 612" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Bootstrap_logo" fillRule="nonzero">
          <path d="M612,510 C612,566.1 566.1,612 510,612 L102,612 C45.9,612 0,566.1 0,510 L0,102 C0,45.9 45.9,0 102,0 L510,0 C566.1,0 612,45.9 612,102 L612,510 Z" id="Path" fill="#563D7C" />
          <path d="M166.3,133 L339.8,133 C371.8,133 397.5,140.3 416.8,155 C436.1,169.7 445.8,191.8 445.8,221.5 C445.8,239.5 441.4,254.9 432.6,267.7 C423.8,280.5 411.2,290.5 394.8,297.5 L394.8,298.5 C416.8,303.2 433.5,313.6 444.8,329.7 C456.1,345.9 461.8,366.1 461.8,390.5 C461.8,404.5 459.3,417.6 454.3,429.7 C449.3,441.9 441.5,452.4 430.8,461.2 C420.1,470 406.5,477 389.8,482.2 C373.1,487.4 353.3,490 330.3,490 L166.3,490 L166.3,133 Z M228.8,282.5 L330.8,282.5 C345.8,282.5 358.3,278.3 368.3,269.7 C378.3,261.1 383.3,248.9 383.3,232.9 C383.3,214.9 378.8,202.2 369.8,194.9 C360.8,187.6 347.8,183.9 330.8,183.9 L228.8,183.9 L228.8,282.5 Z M228.8,439 L339.3,439 C358.3,439 373.1,434.1 383.5,424.2 C394,414.4 399.3,400.4 399.3,382.4 C399.3,364.7 394.1,351.2 383.5,341.6 C372.9,332 358.3,327.4 339.3,327.4 L228.8,327.4 L228.8,439 Z" id="Shape" fill="#FFFFFF" />
        </g>
      </g>
    </svg>
  );
}

export default function BootstrapIcon() {
  return (
    <BaseIcon
      Component={BootstrapSVG}
      title="Bootstrap 3"
    />
  );
}

BootstrapSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

BootstrapSVG.defaultProps = {
  className: '',
};
