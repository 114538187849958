import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function NodeJSSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 746 816" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="nodejs" fillRule="nonzero">
          <path d="M352.858,5.03309755 L20.142,189.6722 C7.85047218,196.312589 0.133641743,209.131624 0,223.131662 L0,592.681896 C0,606.487324 7.66345455,619.20464 20.142,626.141357 L352.858,811.052488 C365.336545,817.649171 380.663455,817.649171 393.142,811.052488 L725.858,626.07335 C738.128415,619.444703 745.841781,606.657467 746,592.681896 L746,223.131662 C745.866358,209.131624 738.149528,196.312589 725.858,189.6722 L393.142,5.03309755 C386.952922,1.69394564 380.027903,-0.0362847957 373,0 C365.972097,-0.0362847957 359.047078,1.69394564 352.858,5.03309755" id="Path" fill="#388E3C" />
          <path d="M352.858,5.03309755 L0,592.613889 C0,606.487324 7.66345455,619.20464 20.142,626.141357 L352.858,811.052488 C365.336545,817.649171 380.663455,817.649171 393.142,811.052488 L746,223.131662 C745.866358,209.131624 738.149528,196.312589 725.858,189.6722 L393.142,5.03309755 C386.952922,1.69394564 380.027903,-0.0362847957 373,0 C365.972097,-0.0362847957 359.047078,1.69394564 352.858,5.03309755" id="Path" fill="#2E7D32" />
          <path d="M352.858,5.03309755 L20.142,189.6722 C7.85047218,196.312589 0.133641743,209.131624 0,223.131662 L352.858,811.052488 C365.336545,817.649171 380.663455,817.649171 393.142,811.052488 L725.858,626.07335 C738.128415,619.444703 745.841781,606.657467 746,592.681896 L393.142,5.10110458 C386.959112,1.73829906 380.033747,-0.0154005754 373,0 C365.972097,-0.0362847957 359.047078,1.69394564 352.858,5.03309755" id="Path" fill="#4CAF50" />
        </g>
      </g>
    </svg>
  );
}


export default function NodeJSIcon() {
  return (
    <BaseIcon
      Component={NodeJSSVG}
      title="NodeJS"
    />
  );
}

NodeJSSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

NodeJSSVG.defaultProps = {
  className: '',
};
