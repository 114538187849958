import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';
import icon from '../images/icons/xc_test.png';

function XCTestImage({ size, className }) {
  return <img src={icon} width={size} height={size} className={className} alt="XCTest icon" />;
}


export default function XCTestIcon() {
  return (
    <BaseIcon
      Component={XCTestImage}
      title="XCTest"
    />
  );
}

XCTestImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

XCTestImage.defaultProps = {
  className: '',
};
