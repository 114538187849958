import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import aaronSlettenMemoji from '../images/home/aaron_sletten_memoji.png';


export default function NavigationBar() {
  return (
    <Navbar bg="light" variant="light">
      <Nav className="mr-auto">
        <Navbar.Brand href="/">
          <img
            src={aaronSlettenMemoji}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Aaron Sletten Memoji"
          />
        </Navbar.Brand>
        <LinkContainer to="/projects">
          <Nav.Link>Projects</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/contact">
          <Nav.Link>Contact</Nav.Link>
        </LinkContainer>
      </Nav>
    </Navbar>
  );
}
