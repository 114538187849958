import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function CSharpSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 409 460" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path d="M390.635,101.665128 L221.838077,4.21076923 C212.114359,-1.40448718 196.201026,-1.40448718 186.477308,4.21076923 L17.6803846,101.665128 C7.95576923,107.27859 0,121.060513 0,132.289231 L0,327.198846 C0.756538462,340.275385 7.43615385,351.105641 17.6803846,357.822051 L186.477308,455.27641 C196.201026,460.891667 212.114359,460.891667 221.838077,455.27641 L390.635,357.822051 C401.626795,350.511538 407.882821,339.507179 408.315385,327.198846 L408.315385,132.289231 C407.920513,120.474487 400.846026,108.558333 390.635,101.665128 Z" id="path-csharp-1" />
        <linearGradient x1="34.9299449%" y1="30.2764605%" x2="81.1608574%" y2="90.7831645%" id="linearGradient-csharp.1">
          <stop stopColor="#3B009B" offset="0%" />
          <stop stopColor="#390086" offset="100%" />
        </linearGradient>
        <linearGradient x1="27.92491%" y1="44.2280018%" x2="54.3599256%" y2="65.6838894%" id="linearGradient-csharp.2">
          <stop stopColor="#340084" offset="0%" />
          <stop stopColor="#290064" offset="100%" />
        </linearGradient>
        <linearGradient x1="29.0416049%" y1="28.3520467%" x2="56.0969723%" y2="56.2976242%" id="linearGradient-csharp.3">
          <stop stopColor="#A47EDE" offset="0%" />
          <stop stopColor="#9866D3" offset="100%" />
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="C_Sharp_logo">
          <g id="Clipped">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-csharp-1" />
            </mask>
            <g id="Path" />
            <g id="Group" mask="url(#mask-2)" fillRule="nonzero">
              <g transform="translate(-26.923077, -13.461538)">
                <rect id="Rectangle" fill="url(#linearGradient-csharp.1)" x="226.681538" y="125.180641" width="240.031795" height="245.442436" />
                <polygon id="Path" fill="url(#linearGradient-csharp.2)" points="466.713333 379.437692 157.809615 200.69359 0.0798717949 200.69359 0.0798717949 496.598846 466.713333 496.598846" />
                <polygon id="Path" fill="url(#linearGradient-csharp.3)" points="0.0798717949 377.006538 466.713333 106.981538 466.713333 0.437051282 0.0798717949 0.437051282" />
              </g>
            </g>
          </g>
          <path d="M68.0525641,229.744487 C67.2529487,96.8441026 245.259359,38.1607692 322.023333,161.648846 L263.120128,195.733462 C227.561923,136.920897 139.273077,157.950513 136.104231,229.74359 C137.055513,295.910641 223.832179,326.646923 263.123718,263.750128 L322.026923,297.833846 C254.37641,415.275 71.4646154,373.021923 68.0525641,229.744487 Z" id="c" fill="#FFFFFF" fillRule="nonzero" />
          <g id="sharp" transform="translate(276.410256, 191.153846)" fill="#FFFFFF" fillRule="nonzero">
            <polygon id="Path" points="16.1538462 0 27.3717949 0 19.7067949 74.0384615 8.27794872 74.0384615" />
            <polygon id="Path" points="40.1835897 0 51.4015385 0 43.7365385 74.0384615 32.3076923 74.0384615" />
            <rect id="Rectangle" x="3.58974359" y="17.5825641" width="56.0897436" height="10.8338462" />
            <rect id="Rectangle" x="0.448717949" y="43.974359" width="56.0897436" height="10.8338462" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function CCharpIcon() {
  return (
    <BaseIcon
      Component={CSharpSVG}
      title="C#"
    />
  );
}

CSharpSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

CSharpSVG.defaultProps = {
  className: '',
};
