import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function ChartJsSVG({ size, className }) {
  return (
    <svg width={`${size}px`} className={className} viewBox="0 0 140 160" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="chartjsSVG-logo" fillRule="nonzero">
          <path d="M135.271,80.556 C112.903,80.995 117.562,95.155 101.798,98.736 C85.784,102.374 83.256,59.625 67.246,59.625 C51.234,59.625 47.687,101.151 27.638,129.659 L27.066,130.466 L70.051,155.279 L135.271,117.628 L135.271,80.556 Z" id="Path" fill="#36A2EB" />
          <path d="M135.271,79.267 C127.783,69.657 122.704,58.609 111.777,58.609 C92.44,58.609 97.528,90.154 76.157,90.154 C54.784,90.154 52.537,56.223 28.325,88.119 C20.61,98.282 14.4,109.614 9.522,120.337 L70.051,155.28 L135.271,117.629 L135.271,79.267 Z" id="Path" fill="#FFCE56" />
          <path d="M4.829,92.334 C12.167,72.013 15.334,55.555 29.343,55.555 C50.714,55.555 55.801,115.594 74.122,109.486 C92.44,103.381 90.404,70.817 118.901,70.817 C124.325,70.817 129.863,74.14 135.272,79.515 L135.272,117.628 L70.052,155.279 L4.829,117.628 L4.829,92.334 L4.829,92.334 Z" id="Path" fill="#FE6184" opacity="0.8" />
          <path d="M70,160 L0.708,120.001 L0.708,40 L70,0 L139.292,40 L139.292,120 L70,160 Z M8.849,115.301 L70,150.602 L131.151,115.301 L131.151,44.7 L70,9.399 L8.849,44.7 L8.849,115.301 Z" id="Shape" fill="#E7E9ED" />
        </g>
      </g>
    </svg>
  );
}

export default function ChartJSIcon() {
  return (
    <BaseIcon
      Component={ChartJsSVG}
      title="ChartJS"
    />
  );
}

ChartJsSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

ChartJsSVG.defaultProps = {
  className: '',
};
