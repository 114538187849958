import React from 'react';
import { FaNodeJs } from 'react-icons/fa';
import BaseIcon from './BaseIcon';

export default function JavaScriptIcon() {
  return (
    <BaseIcon
      Component={FaNodeJs}
      title="JavaScript"
    />
  );
}
