import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function ObjectiveCSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 409 460" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path d="M390.635,101.665128 L221.838077,4.21076923 C212.114359,-1.40448718 196.201026,-1.40448718 186.477308,4.21076923 L17.6803846,101.665128 C7.95576923,107.27859 0,121.060513 0,132.289231 L0,327.198846 C0.756538462,340.275385 7.43615385,351.105641 17.6803846,357.822051 L186.477308,455.27641 C196.201026,460.891667 212.114359,460.891667 221.838077,455.27641 L390.635,357.822051 C401.626795,350.511538 407.882821,339.507179 408.315385,327.198846 L408.315385,132.289231 C407.920513,120.474487 400.846026,108.558333 390.635,101.665128 Z" id="path-1" />
        <linearGradient x1="34.9299449%" y1="30.2764605%" x2="81.1608574%" y2="90.7831645%" id="linearGradient-3">
          <stop stopColor="#059B00" offset="0%" />
          <stop stopColor="#047B00" offset="100%" />
        </linearGradient>
        <linearGradient x1="27.92491%" y1="44.2280018%" x2="54.2231735%" y2="65.5728951%" id="linearGradient-4">
          <stop stopColor="#058800" offset="0%" />
          <stop stopColor="#046400" offset="100%" />
        </linearGradient>
        <linearGradient x1="29.4600605%" y1="28.7842709%" x2="56.0969723%" y2="56.2976242%" id="linearGradient-5">
          <stop stopColor="#85DE7E" offset="0%" />
          <stop stopColor="#52B54B" offset="100%" />
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Objective-C">
          <g id="Clipped">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Path" />
            <g id="Group" mask="url(#mask-2)" fillRule="nonzero">
              <g transform="translate(-26.923077, -13.461538)">
                <rect id="Rectangle" fill="url(#linearGradient-3)" x="226.681538" y="125.180641" width="240.031795" height="245.442436" />
                <polygon id="Path" fill="url(#linearGradient-4)" points="466.713333 379.437692 157.809615 200.69359 0.0798717949 200.69359 0.0798717949 496.598846 466.713333 496.598846" />
                <polygon id="Path" fill="url(#linearGradient-5)" points="0.0798717949 377.006538 466.713333 106.981538 466.713333 0.437051282 0.0798717949 0.437051282" />
              </g>
            </g>
          </g>
          <path d="M68.0525641,229.744487 C67.2529487,96.8441026 245.259359,38.1607692 322.023333,161.648846 L263.120128,195.733462 C227.561923,136.920897 139.273077,157.950513 136.104231,229.74359 C137.055513,295.910641 223.832179,326.646923 263.123718,263.750128 L322.026923,297.833846 C254.37641,415.275 71.4646154,373.021923 68.0525641,229.744487 Z" id="c" fill="#FFFFFF" fillRule="nonzero" />
          <path d="M293.544922,252.849609 C303.681641,252.849609 309.804688,245.847656 309.804688,234.099609 L309.804688,227.625 C309.804688,215.876953 303.681641,208.875 293.544922,208.875 C283.408203,208.875 277.285156,215.876953 277.285156,227.625 L277.285156,234.099609 C277.285156,245.847656 283.408203,252.849609 293.544922,252.849609 Z M293.544922,245.466797 C288.447266,245.466797 285.751953,241.628906 285.751953,234.011719 L285.751953,227.712891 C285.751953,220.095703 288.447266,216.257812 293.544922,216.257812 C298.642578,216.257812 301.337891,220.095703 301.337891,227.712891 L301.337891,234.011719 C301.337891,241.628906 298.642578,245.466797 293.544922,245.466797 Z M332.246094,252 C341.298828,252 346.894531,247.371094 346.894531,239.958984 C346.894531,234.480469 342.880859,230.232422 337.255859,229.822266 L337.255859,229.324219 C341.650391,228.650391 344.873047,224.8125 344.873047,220.242188 C344.873047,213.650391 339.980469,209.724609 331.865234,209.724609 L316.308594,209.724609 L316.308594,252 L332.246094,252 Z M329.257812,227.126953 L324.453125,227.126953 L324.453125,216.140625 L329.609375,216.140625 C334.0625,216.140625 336.494141,218.103516 336.494141,221.736328 C336.494141,225.134766 333.857422,227.126953 329.257812,227.126953 Z M329.667969,245.466797 L324.453125,245.466797 L324.453125,233.220703 L329.609375,233.220703 C335.322266,233.220703 338.222656,235.212891 338.222656,239.226562 C338.222656,243.503906 335.46875,245.466797 329.667969,245.466797 Z M366.523438,252.849609 C375.458984,252.849609 380.732422,247.664062 380.732422,238.787109 L380.732422,209.724609 L357.207031,209.724609 L357.207031,216.873047 L372.382812,216.873047 L372.382812,238.669922 C372.382812,242.976562 370.273438,245.320312 366.40625,245.320312 C362.861328,245.320312 360.429688,243.005859 360.341797,239.460938 L352.314453,239.460938 C352.34375,247.605469 357.910156,252.849609 366.523438,252.849609 Z" id="OBJ" fill="#FFFFFF" fillRule="nonzero" />
        </g>
      </g>
    </svg>
  );
}

export default function ObjectiveCIcon() {
  return (
    <BaseIcon
      Component={ObjectiveCSVG}
      title="Objective C"
    />
  );
}

ObjectiveCSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

ObjectiveCSVG.defaultProps = {
  className: '',
};
