import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';
import gamecenterIconImage from '../images/icons/gamecenter_icon.png';

function GameCenterImage({ size, className }) {
  return <img src={gamecenterIconImage} width={size} height={size} className={className} alt="gamecenter icon" />;
}


export default function GameCenterIcon() {
  return (
    <BaseIcon
      Component={GameCenterImage}
      title="GameCenter"
    />
  );
}

GameCenterImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

GameCenterImage.defaultProps = {
  className: '',
};
