import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from './BaseIcon';


function PostgresSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="-64.85325 -111.279 562.0615 667.674">
      <g fill="none" stroke="#fff" strokeWidth="12.465" strokeLinecap="round" strokeLinejoin="round">
        <path d="M323.205 324.227c2.833-23.6 1.984-27.062 19.563-23.239l4.463.392c13.517.615 31.199-2.174 41.587-7 22.362-10.376 35.622-27.7 13.572-23.148-50.297 10.376-53.755-6.655-53.755-6.655 53.111-78.803 75.313-178.836 56.149-203.322C352.514-5.534 262.036 26.05 260.522 26.87l-.482.09c-9.938-2.063-21.06-3.295-33.554-3.497-22.761-.374-40.032 5.967-53.133 15.904 0 0-161.408-66.498-153.899 83.628 1.597 31.936 45.777 241.655 98.47 178.31 19.259-23.163 37.871-42.748 37.871-42.748 9.242 6.14 20.307 9.272 31.912 8.147l.897-.765c-.281 2.876-.157 5.69.359 9.02-13.572 15.166-9.584 17.83-36.723 23.415-27.457 5.66-11.326 15.734-.797 18.367 12.768 3.193 42.305 7.716 62.268-20.224l-.795 3.188c5.325 4.26 4.965 30.62 5.72 49.452.756 18.834 2.017 36.41 5.856 46.771 3.839 10.36 8.369 37.05 44.036 29.406 29.809-6.388 52.6-15.582 54.677-101.107" fill="#000" stroke="#000" strokeWidth="37.395" strokeLinecap="butt" strokeLinejoin="miter" />
        <path d="M402.395 271.23c-50.302 10.376-53.76-6.655-53.76-6.655 53.111-78.808 75.313-178.843 56.153-203.326-52.27-66.785-142.752-35.2-144.262-34.38l-.486.087c-9.938-2.063-21.06-3.292-33.56-3.496-22.761-.373-40.026 5.967-53.127 15.902 0 0-161.411-66.495-153.904 83.63 1.597 31.938 45.776 241.657 98.471 178.312 19.26-23.163 37.869-42.748 37.869-42.748 9.243 6.14 20.308 9.272 31.908 8.147l.901-.765c-.28 2.876-.152 5.69.361 9.02-13.575 15.166-9.586 17.83-36.723 23.415-27.459 5.66-11.328 15.734-.796 18.367 12.768 3.193 42.307 7.716 62.266-20.224l-.796 3.188c5.319 4.26 9.054 27.711 8.428 48.97-.626 21.258-1.044 35.853 3.147 47.253 4.191 11.4 8.368 37.05 44.042 29.406 29.809-6.388 45.256-22.942 47.405-50.555 1.525-19.63 4.976-16.729 5.194-34.28l2.768-8.309c3.192-26.61.507-35.196 18.872-31.203l4.463.392c13.517.615 31.208-2.174 41.591-7 22.358-10.376 35.618-27.7 13.573-23.148z" fill="#336791" stroke="none" />
        <path d="M215.866 286.484c-1.385 49.516.348 99.377 5.193 111.495 4.848 12.118 15.223 35.688 50.9 28.045 29.806-6.39 40.651-18.756 45.357-46.05 3.466-20.083 10.148-75.855 11.005-87.282M173.104 38.256S11.583-27.76 19.092 122.366c1.597 31.937 45.779 241.663 98.473 178.315 19.256-23.166 36.671-41.335 36.671-41.335M260.349 26.207c-5.591 1.753 89.848-34.889 144.087 34.417 19.159 24.484-3.043 124.52-56.153 203.33" />
        <path d="M348.282 263.953s3.461 17.036 53.764 6.653c22.04-4.552 8.776 12.774-13.577 23.155-18.345 8.514-59.474 10.696-60.146-1.069-1.729-30.355 21.647-21.133 19.96-28.739-1.525-6.85-11.979-13.573-18.894-30.338-6.037-14.633-82.796-126.849 21.287-110.183 3.813-.789-27.146-99.002-124.553-100.599-97.385-1.597-94.19 119.762-94.19 119.762" strokeLinejoin="bevel" />
        <path d="M188.604 274.334c-13.577 15.166-9.584 17.83-36.723 23.417-27.459 5.66-11.326 15.733-.797 18.365 12.768 3.195 42.307 7.718 62.266-20.229 6.078-8.509-.036-22.086-8.385-25.547-4.034-1.67-9.428-3.765-16.361 3.994z" />
        <path d="M187.715 274.07c-1.368-8.918 2.93-19.529 7.536-31.943 6.922-18.626 22.893-37.255 10.117-96.339-9.523-44.029-73.396-9.163-73.436-3.193-.039 5.968 2.889 30.26-1.067 58.548-5.162 36.913 23.488 68.132 56.479 64.938" />
        <path d="M172.517 141.7c-.288 2.04 3.733 7.48 8.976 8.207 5.234.73 9.714-3.522 9.998-5.559.284-2.039-3.732-4.285-8.977-5.015-5.237-.73-9.719.333-9.996 2.367z" fill="#fff" strokeWidth="4.155" strokeLinecap="butt" strokeLinejoin="miter" />
        <path d="M331.941 137.543c.284 2.04-3.732 7.48-8.976 8.207-5.238.73-9.718-3.522-10.005-5.559-.277-2.039 3.74-4.285 8.979-5.015 5.239-.73 9.718.333 10.002 2.368z" fill="#fff" strokeWidth="2.078" strokeLinecap="butt" strokeLinejoin="miter" />
        <path d="M350.676 123.432c.863 15.994-3.445 26.888-3.988 43.914-.804 24.748 11.799 53.074-7.191 81.435" />
      </g>
    </svg>
  );
}


export default function PostgresIcon() {
  return (
    <BaseIcon
      Component={PostgresSVG}
      title="Postgres"
    />
  );
}

PostgresSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

PostgresSVG.defaultProps = {
  className: '',
};
