import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';
import icon from '../images/icons/device_activity.png';

function DeviceActivityImage({ size, className }) {
  return <img src={icon} width={size} height={size} className={className} alt="Device Activity icon" />;
}


export default function DeviceActivityIcon() {
  return (
    <BaseIcon
      Component={DeviceActivityImage}
      title="Device Activity"
    />
  );
}

DeviceActivityImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

DeviceActivityImage.defaultProps = {
  className: '',
};
