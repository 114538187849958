import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from '../BaseIcon';
import fireOrbImage from '../../images/projects/2015_geology_game/08_fire_orb.png';

function FireOrbImage({ size, className }) {
  return <img src={fireOrbImage} height={size} className={className} alt="fire orb icon" />;
}


export default function FireOrbIcon() {
  return (
    <BaseIcon
      Component={FireOrbImage}
    />
  );
}

FireOrbImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

FireOrbImage.defaultProps = {
  className: '',
};
