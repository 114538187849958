import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function MongoDBSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 117 255" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Mongo-DB-Icon" fillRule="nonzero">
          <path d="M114.931252,102.146781 C101.194659,42.4875661 72.5440506,26.5915252 65.2832801,15.4054224 C62.241606,10.4011132 59.5924059,5.2986803 57.3356799,0 C56.9432058,5.2986803 56.2563762,8.63488641 51.6448057,12.6579585 C42.4216648,20.7041026 3.46861212,52.0055659 0.132582421,119.809049 C-2.90909172,183.000718 47.3275908,221.95583 53.9996502,225.978902 C59.1018133,228.431995 65.2832801,225.978902 68.3249542,223.722057 C92.5602288,207.335398 125.62617,163.768471 114.931252,102.146781" id="Path" fill="#10AA50" />
          <path d="M57.5519481,192 C56.2857143,207.313846 55.3116883,216.230769 52,224.953846 C52,224.953846 54.2402597,239.589231 55.7012987,255 L61.1558442,255 C62.4220779,244.144615 64.3701299,233.289231 67,222.627692 C60.0844156,219.429231 57.8441558,205.472308 57.5519481,192 Z" id="Path" fill="#B8C4C2" />
          <path d="M69.4130156,223 L69.4130156,223 C62.6980803,219.863297 60.7517222,205.258022 60.4597685,191.632967 C62.1141729,169.087912 62.6007624,146.542857 61.9195371,123.89978 C61.5302655,112.137143 62.1141729,14.3112088 59,0 C61.1409939,4.80307692 63.5739414,9.50813187 66.2015248,14.1151648 C73.4030496,25.3876923 101.527923,41.2672527 114.957794,101.158681 C125.468128,162.814505 93.1585839,206.434286 69.4130156,223 Z" id="Path" fill="#12924F" />
        </g>
      </g>
    </svg>
  );
}


export default function MongoDBIcon() {
  return (
    <BaseIcon
      Component={MongoDBSVG}
      title="Mongo DB"
    />
  );
}

MongoDBSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

MongoDBSVG.defaultProps = {
  className: '',
};
