import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

export default function IconList({ iconNames }) {
  const icons = iconNames.map((iconName) => (
    <Icon key={iconName} name={iconName} />
  ));

  return icons;
}


IconList.propTypes = {
  iconNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};
