import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import TableBaseIcon from './TableBaseIcon';

export default function CompleteTaskIcon() {
  return (
    <TableBaseIcon
      Component={FaCheckCircle}
      title="Complete"
      color="limegreen"
    />
  );
}
