import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from './BaseIcon';


function TuriTroveSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 103 88" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Trove_dark" transform="translate(4.000000, 3.000000)" stroke="#000000" strokeWidth="5">
          <g id="Group" transform="translate(-0.000000, -0.000000)">
            <polygon id="Polygon" transform="translate(47.500000, 40.500000) scale(-1, 1) rotate(-180.000000) translate(-47.500000, -40.500000) " points="47.5 0 95 56.5361666 78.7896825 81 15.8333333 81 0 56.5361666" />
            <line x1="4.78571429" y1="23.625" x2="90.2157146" y2="23.625" id="Line-2" fill="#D8D8D8" fillRule="nonzero" strokeLinecap="square" />
            <line x1="30.5357143" y1="27" x2="46.3216984" y2="78.2385534" id="Line-3" fill="#D8D8D8" fillRule="nonzero" strokeLinecap="square" />
            <line x1="47.5000003" y1="27" x2="64.464286" y2="78.2385534" id="Line-3" fill="#D8D8D8" fillRule="nonzero" strokeLinecap="square" transform="translate(55.982143, 52.619277) scale(-1, 1) translate(-55.982143, -52.619277) " />
            <line x1="64.4642854" y1="0.675" x2="61.0714283" y2="20.5466857" id="Line-4" fill="#D8D8D8" fillRule="nonzero" strokeLinecap="square" transform="translate(62.767857, 10.610843) scale(-1, 1) translate(-62.767857, -10.610843) " />
            <line x1="33.9285714" y1="0.675" x2="30.5357143" y2="20.5466857" id="Line-4" fill="#D8D8D8" fillRule="nonzero" strokeLinecap="square" />
          </g>
        </g>
      </g>
    </svg>
  );
}


export default function TuriTroveIcon() {
  return (
    <BaseIcon
      Component={TuriTroveSVG}
      title="Turi Trove"
    />
  );
}

TuriTroveSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

TuriTroveSVG.defaultProps = {
  className: '',
};
