import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function CSSSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 103 117" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="CSS-Icon" fillRule="nonzero">
          <polygon id="polygon2989" fill="#264DE4" points="102.653819 0.679422677 93.3275037 105.157809 51.4053675 116.780002 9.59866015 105.174178 0.282222227 0.679422677" />
          <polygon id="polygon2991" fill="#2965F1" points="85.3431547 98.5049842 93.3131104 9.22341837 51.4680208 9.22341837 51.4680208 107.896493" />
          <polygon id="polygon2993" fill="#EBEBEB" points="21.6399537 47.9790212 22.7885981 60.7947325 51.4680208 60.7947325 51.4680208 47.9790212" />
          <polygon id="polygon2995" fill="#EBEBEB" points="51.4680208 22.0394119 51.4237119 22.0394119 19.3319403 22.0394119 20.4969537 34.8554054 51.4680208 34.8554054" />
          <polygon id="polygon2997" fill="#EBEBEB" points="51.4680208 94.5970531 51.4680208 81.2631817 51.4118586 81.2781395 37.1387517 77.4241128 36.2263272 67.2028704 29.290716 67.2028704 23.361227 67.2028704 25.1567248 87.3255974 51.4090364 94.6134219" />
          <polygon id="polygon3005" fill="#FFFFFF" points="67.2050144 60.7947325 65.717421 77.4159283 51.4237119 81.2739062 51.4237119 94.6072131 77.6969079 87.3255974 77.8896657 85.1603885 80.9012591 51.420439 81.2139613 47.9790212 83.5276191 22.0394119 51.4237119 22.0394119 51.4237119 34.8554054 69.4825478 34.8554054 68.3164055 47.9790212 51.4237119 47.9790212 51.4237119 60.7947325" />
        </g>
      </g>
    </svg>
  );
}


export default function CSSIcon() {
  return (
    <BaseIcon
      Component={CSSSVG}
      title="CSS 3"
    />
  );
}

CSSSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

CSSSVG.defaultProps = {
  className: '',
};
