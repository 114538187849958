import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function AVFoundationSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 978 1018" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="AV-Foundation-Icon">
          <g id="Stack">
            <g id="First" transform="translate(0.000000, 269.000000)" fill="#177DD3">
              <path d="M108.810534,291.146295 L385.494561,79.1940334 C446.4943,32.4655227 531.25576,32.4655227 592.255498,79.1940334 L868.939526,291.146295 C914.974506,326.411136 923.705429,392.317662 888.440588,438.352642 C882.825891,445.682109 876.268993,452.239007 868.939526,457.853705 L592.255498,669.805967 C531.25576,716.534477 446.4943,716.534477 385.494561,669.805967 L108.810534,457.853705 C62.7755537,422.588864 54.0446307,356.682338 89.3094712,310.647358 C94.9241687,303.317891 101.481067,296.760993 108.810534,291.146295 Z" id="Path" />
              <polygon id="Line" points="880.328296 283.5 910.095154 283.5 910.095154 374.5" />
              <polygon id="Line" transform="translate(82.213316, 329.000000) scale(-1, 1) translate(-82.213316, -329.000000) " points="67.6597748 284.017045 96.7668577 283.5 96.7668577 374.5" />
            </g>
            <g id="Second" transform="translate(0.000000, 178.000000)" fill="#52B1F1">
              <path d="M108.810534,291.146295 L385.494561,79.1940334 C446.4943,32.4655227 531.25576,32.4655227 592.255498,79.1940334 L868.939526,291.146295 C914.974506,326.411136 923.705429,392.317662 888.440588,438.352642 C882.825891,445.682109 876.268993,452.239007 868.939526,457.853705 L592.255498,669.805967 C531.25576,716.534477 446.4943,716.534477 385.494561,669.805967 L108.810534,457.853705 C62.7755537,422.588864 54.0446307,356.682338 89.3094712,310.647358 C94.9241687,303.317891 101.481067,296.760993 108.810534,291.146295 Z" id="Path" />
              <polygon id="Line" points="880.328296 286.5 910.095154 286.5 910.095154 374.5" />
              <polygon id="Line" transform="translate(82.213316, 329.500000) scale(-1, 1) translate(-82.213316, -329.500000) " points="67.6597748 285.011364 96.7668577 284.5 96.7668577 374.5" />
            </g>
            <g id="Third">
              <path d="M108.810534,379.146295 L385.494561,167.194033 C446.4943,120.465523 531.25576,120.465523 592.255498,167.194033 L868.939526,379.146295 C914.974506,414.411136 923.705429,480.317662 888.440588,526.352642 C882.825891,533.682109 876.268993,540.239007 868.939526,545.853705 L592.255498,757.805967 C531.25576,804.534477 446.4943,804.534477 385.494561,757.805967 L108.810534,545.853705 C62.7755537,510.588864 54.0446307,444.682338 89.3094712,398.647358 C94.9241687,391.317891 101.481067,384.760993 108.810534,379.146295 Z" id="Path" fill="#9ABEE3" />
              <polygon id="Line" fill="#9ABEE3" points="880.328296 374.5 910.095154 374.5 910.095154 462.5" />
              <polygon id="Line" fill="#9ABEE3" transform="translate(82.213316, 418.500000) scale(-1, 1) translate(-82.213316, -418.500000) " points="67.6597748 375 96.7668577 374.5 96.7668577 462.5" />
              <path d="M108.810534,291.146295 L385.494561,79.1940334 C446.4943,32.4655227 531.25576,32.4655227 592.255498,79.1940334 L868.939526,291.146295 C914.974506,326.411136 923.705429,392.317662 888.440588,438.352642 C882.825891,445.682109 876.268993,452.239007 868.939526,457.853705 L592.255498,669.805967 C531.25576,716.534477 446.4943,716.534477 385.494561,669.805967 L108.810534,457.853705 C62.7755537,422.588864 54.0446307,356.682338 89.3094712,310.647358 C94.9241687,303.317891 101.481067,296.760993 108.810534,291.146295 Z" id="Path" fill="#D9E7F6" />
            </g>
          </g>
          <g id="Video-Icon" transform="translate(229.246924, 166.682617)" fill="#6D9CBE">
            <path d="M32.2617003,222.938892 L183.521502,104.336839 C202.772258,89.2424182 229.852939,89.2845448 249.05664,104.438786 L389.092302,214.945304 C402.965933,225.89342 405.337532,246.015422 394.389416,259.889053 C392.938567,261.727592 391.290264,263.401297 389.474126,264.880091 L242.445894,384.598146 C223.308817,400.180552 195.949244,400.492432 176.461929,385.350317 L32.3725349,273.389372 C18.4171298,262.545685 15.8945841,242.442048 26.7382714,228.486643 C28.3454213,226.418304 30.2004421,224.555114 32.2617003,222.938892 Z" id="Path" />
            <path d="M300.121639,118.110456 L371.892021,174.134819 L463.674012,170.85379 C475.816521,170.419718 485.308084,160.224386 484.874013,148.081878 C484.639184,141.512879 481.479343,135.393007 476.259627,131.397828 L344.47408,30.5289586 C333.071404,21.8013324 316.75257,23.9698885 308.024944,35.3725641 C304.756967,39.642188 302.893055,44.819961 302.689884,50.1928638 L300.121639,118.110456 L300.121639,118.110456 Z" id="Path-2" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function AVFoundationIcon() {
  return (
    <BaseIcon
      Component={AVFoundationSVG}
      title="AVFoundation"
    />
  );
}

AVFoundationSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

AVFoundationSVG.defaultProps = {
  className: '',
};
