import PropTypes from 'prop-types';
import React from 'react';
import BaseIcon from './BaseIcon';


function TuriBoltSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} version="1.1" x="0px" y="0px" viewBox="0 0 100 100">
      <path d="M45.3,5.6l-15.4,5.6c-0.4,0.1-0.7,0.3-1,0.6L11.2,29.5c-0.3,0.3-0.5,0.7-0.6,1.1L5.7,45.7c-0.3,1,0,2.1,0.7,2.7l14.7,13.2  c0.7,0.6,1.6,0.8,2.5,0.6l12.3-3.7l35.1,35.1c2.9,2.9,11.5-0.7,16.6-5.8c5-5,8.7-13.7,5.8-16.6L58.3,36.1l3.8-12.4  c0.3-0.9,0.1-1.8-0.6-2.5L48.1,6.3C47.4,5.5,46.3,5.2,45.3,5.6z M12,43l3.2-10.1c0-0.1,0.1-0.2,0.2-0.3L32.1,16  c0.1-0.1,0.2-0.1,0.2-0.2L42.7,12c0.5-0.2,1,0.3,0.9,0.8l-2.9,10.5c0,0.1-0.1,0.2-0.2,0.3l-17,17c-0.1,0.1-0.2,0.1-0.3,0.2  l-10.4,3.1C12.4,44.1,11.9,43.6,12,43z M41.8,27.2l9.8,10.5c0.2,0.3,0.2,0.7,0,0.9l-13,13c-0.3,0.3-0.7,0.3-0.9,0l-10.5-9.8  c-0.3-0.3-0.3-0.7,0-1l13.8-13.8C41.2,26.9,41.6,26.9,41.8,27.2z M23.2,56.5l-9.7-8.8c-0.4-0.4-0.2-1,0.3-1.1l10.4-3.1  c0.2-0.1,0.5,0,0.7,0.2l9.5,8.8c0.4,0.4,0.2,1-0.3,1.1l-10.1,3.1C23.6,56.7,23.4,56.6,23.2,56.5z M89.2,74.4c-0.1,1.5-1.6,6-5.3,9.6  c-3.7,3.7-8.1,5.1-9.6,5.3L71.9,87c3-1,6.5-3.1,9.1-5.8c2.6-2.6,4.7-6.1,5.8-9.1L89.2,74.4z M84.2,69.5c-0.5,2.2-2.1,6.1-5.5,9.5  c-3.3,3.3-7.3,5-9.5,5.5l-3.5-3.5c2.9-1.1,6.3-3.2,9-5.9c2.7-2.7,4.8-6.1,5.9-9L84.2,69.5z M78.2,63.4c-0.5,2.1-2.2,5.8-5.6,9.3  c-3.5,3.5-7.2,5.1-9.3,5.6l-3.6-3.6c2.9-1.2,6.3-3.5,8.9-6.1c2.6-2.6,4.8-5.9,6.1-8.9L78.2,63.4z M72.2,57.4  c-0.6,2.1-2.5,5.8-5.8,9.1c-3.3,3.3-7,5.2-9.1,5.8l-3.6-3.6c3-1.3,6.3-3.8,8.7-6.2s4.9-5.8,6.2-8.7L72.2,57.4z M66.1,51.4  c-0.7,2-2.6,5.5-6,8.9c-3.4,3.4-6.9,5.3-8.9,6l-3.7-3.7c2.9-1.4,6.2-3.9,8.6-6.3c2.4-2.4,4.9-5.7,6.3-8.6L66.1,51.4z M53.9,54.1  c-3.5,3.5-6.9,5.4-8.7,6.2l-3.8-3.8l14.9-14.9l3.8,3.8C59.4,47.2,57.4,50.6,53.9,54.1z M56.4,23.3c0.2,0.2,0.2,0.4,0.1,0.6  l-3.1,10.1c-0.2,0.5-0.8,0.6-1.1,0.3l-8.8-9.5c-0.2-0.2-0.2-0.4-0.2-0.6l3-10.6c0.1-0.5,0.8-0.7,1.1-0.3L56.4,23.3z" />
    </svg>
  );
}


export default function TuriBoltIcon() {
  return (
    <BaseIcon
      Component={TuriBoltSVG}
      title="Turi Bolt"
    />
  );
}

TuriBoltSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

TuriBoltSVG.defaultProps = {
  className: '',
};
