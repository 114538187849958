import React from 'react';
import { FaBluetoothB } from 'react-icons/fa';
import BaseIcon from './BaseIcon';

export default function Bluetooth2Icon() {
  return (
    <BaseIcon
      Component={FaBluetoothB}
      title="Bluetooth 2.0"
    />
  );
}
