import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';
import pillowIconImage from '../images/icons/pillow_logo.png';

function PillowImage({ size, className }) {
  return <img src={pillowIconImage} width={size} height={size} className={className} alt="pillow icon" />;
}


export default function PillowIcon() {
  return (
    <BaseIcon
      Component={PillowImage}
      title="Pillow"
    />
  );
}

PillowImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

PillowImage.defaultProps = {
  className: '',
};
