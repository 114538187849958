import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Container } from 'react-bootstrap';
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from 'react-router-dom';
import NavigationBar from './general/NavigationBar';
import Contact from './pages/contact';
import ErrorPage from './pages/error';
import Home from './pages/home';
import Projects from './pages/projects';
import CentroidCalculatorPage from './projects/CentroidCalculatorPage';
import ComputerControlledCarPage from './projects/ComputerControlledCarPage';
import EndangeredSpeciesMapPage from './projects/EndangeredSpeciesMapPage';
import FrankensteinPCPage from './projects/FrankensteinPCPage';
import GeologyGamePage from './projects/GeologyGamePage';
import InternalDatabaseToolPage from './projects/InternalDatabaseToolPage';
import LabMonitorPage from './projects/LabMonitorPage';
import PHPDatabaseWebsitePage from './projects/PHPDatabaseWebsitePage';
import ParachutezPage from './projects/ParachutezPage';
import QuadcopterPositioningPage from './projects/QuadcopterPositioningPage';
import StrawberryImagingRobotPage from './projects/StrawberryImagingRobotPage';
import TankGamePage from './projects/TankGamePage';
import TimestampOCR from './projects/TimestampOCR';

function Root() {
  return (
    <>
      <NavigationBar />
      <Container fluid={false}>
        <Outlet />
      </Container>
      <ScrollRestoration />
    </>
  );
}

export default function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          element: <Home />,
        },
        {
          path: 'contact',
          element: <Contact />,
        },
        {
          path: 'projects',
          element: <Projects />,
        },
        {
          path: 'projects/timestamp_ocr',
          element: <TimestampOCR />,
        },
        {
          path: 'projects/lab_monitor',
          element: <LabMonitorPage />,
        },
        {
          path: 'projects/strawberry_imaging_robot',
          element: <StrawberryImagingRobotPage />,
        },
        {
          path: 'projects/internal_database_tool',
          element: <InternalDatabaseToolPage />,
        },
        {
          path: 'projects/quadcopter_positioning',
          element: <QuadcopterPositioningPage />,
        },
        {
          path: 'projects/centroid_calculator',
          element: <CentroidCalculatorPage />,
        },
        {
          path: 'projects/endangered_species_map',
          element: <EndangeredSpeciesMapPage />,
        },
        {
          path: 'projects/php_database_website',
          element: <PHPDatabaseWebsitePage />,
        },
        {
          path: 'projects/computer_controlled_car',
          element: <ComputerControlledCarPage />,
        },
        {
          path: 'projects/geology_game',
          element: <GeologyGamePage />,
        },
        {
          path: 'projects/tank_game',
          element: <TankGamePage />,
        },
        {
          path: 'projects/parachutez',
          element: <ParachutezPage />,
        },
        {
          path: 'projects/frankenstein_pc',
          element: <FrankensteinPCPage />,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
