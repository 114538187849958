import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from '../BaseIcon';
import emeraldImage from '../../images/projects/2015_geology_game/04_emerald.png';

function EmeraldImage({ size, className }) {
  return <img src={emeraldImage} height={size} className={className} alt="emerald icon" />;
}


export default function EmeraldIcon() {
  return (
    <BaseIcon
      Component={EmeraldImage}
    />
  );
}

EmeraldImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

EmeraldImage.defaultProps = {
  className: '',
};
