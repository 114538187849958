import React from 'react';
import { FcLinux } from 'react-icons/fc';
import BaseIcon from './BaseIcon';

export default function LinuxIcon() {
  return (
    <BaseIcon
      Component={FcLinux}
      title="Linux"
    />
  );
}
