import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function MomentJSSVG({ size, className }) {
  return (
    <svg width={`${size}px`} className={className} viewBox="0 0 2500 2500" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Moment-JS-Icon" fillRule="nonzero">
          <path d="M1250,2500 C1940.35156,2500 2500,1940.35156 2500,1250 C2500,559.648438 1940.35156,0 1250,0 C559.648438,0 0,559.648438 0,1250 C0,1940.35156 559.648438,2500 1250,2500 Z M1250,2312.5 C663.193359,2312.5 187.5,1836.80664 187.5,1250 C187.5,663.193359 663.193359,187.5 1250,187.5 C1836.80664,187.5 2312.5,663.193359 2312.5,1250 C2312.5,1836.80664 1836.80664,2312.5 1250,2312.5 Z" id="Shape" fill="#376660" />
          <path d="M1250,250 C1802.28516,250 2250,697.714844 2250,1250 C2250,1802.28516 1802.28516,2250 1250,2250 C697.714844,2250 250,1802.28516 250,1250 C250,697.714844 697.714844,250 1250,250 Z M1250,312.49987 C1215.47852,312.49987 1187.5,340.625 1187.5,375.429688 L1187.5,1187.5 L749.814453,1187.5 C733.250634,1187.48428 717.363417,1194.06999 705.668447,1205.79978 C693.973477,1217.52957 687.435036,1233.4363 687.49952,1250 C687.49952,1284.52148 715.976562,1312.5 749.707031,1312.5 L1312.50028,1312.5 L1312.50028,375.058594 C1312.54941,358.462085 1305.98235,342.530235 1294.25229,330.789182 C1282.52224,319.048129 1266.59655,312.466146 1250,312.49987 Z" id="Combined-Shape" fill="#529990" />
        </g>
      </g>
    </svg>
  );
}

export default function MomentJSIcon() {
  return (
    <BaseIcon
      Component={MomentJSSVG}
      title="MomentJS"
    />
  );
}

MomentJSSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

MomentJSSVG.defaultProps = {
  className: '',
};
