import React from 'react';
import { DiMysql } from 'react-icons/di';
import BaseIcon from './BaseIcon';

export default function MySQLIcon() {
  return (
    <BaseIcon
      Component={DiMysql}
      title="MySQL"
    />
  );
}
