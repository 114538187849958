import PropType from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

export default function ResumeDownloadButton({ className }) {
  return (
    <Button
      target="_blank"
      href={`${process.env.PUBLIC_URL}/Aaron Sletten's Resume 2024.pdf`}
      className={className}
    >
      View
    </Button>
  );
}

ResumeDownloadButton.propTypes = {
  className: PropType.string,
};

ResumeDownloadButton.defaultProps = {
  className: null,
};
