import PropTypes from 'prop-types';
import React from 'react';
import AVFoundationIcon from './AVFoundationIcon';
import ArduinoIcon from './ArduinoIcon';
import Bluetooth2Icon from './Bluetooth2Icon';
import BluetoothLEIcon from './BluetoothLEIcon';
import Bootstrap3Icon from './Bootstrap3Icon';
import Bootstrap4Icon from './Bootstrap4Icon';
import CIcon from './CIcon';
import CSSIcon from './CSSIcon';
import CSharpIcon from './CSharpIcon';
import CeleryIcon from './CeleryIcon';
import ChartJSIcon from './ChartJSIcon';
import CircuitsIcon from './CircuitsIcon';
import DeviceActivityIcon from './DeviceActivityIcon';
import DjangoIcon from './DjangoIcon';
import GameCenterIcon from './GamecenterIcon';
import HTMLIcon from './HTMLIcon';
import HandlebarsIcon from './HandlebarsIcon';
import HerokuIcon from './HerokuIcon';
import IOS11Icon from './IOS11Icon';
import IllustratorIcon from './IllustratorIcon';
import JavaIcon from './JavaIcon';
import JavaScriptIcon from './JavaScriptIcon';
import JqueryIcon from './JqueryIcon';
import KMLIcon from './KMLIcon';
import KubernetesIcon from './KubernetesIcon';
import LinuxIcon from './LinuxIcon';
import MahappsIcon from './MahappsIcon';
import MomentJSIcon from './MomentJSIcon';
import MongoDBIcon from './MongoDBIcon';
import MySQLIcon from './MySQLIcon';
import NGINXIcon from './NGINXIcon';
import NodeJSIcon from './NodeJSIcon';
import ObjectiveCIcon from './ObjectiveCIcon';
import PHPIcon from './PHPIcon';
import PostgresIcon from './PostgresIcon';
import PyTorchIcon from './PyTorchIcon';
import Python3Icon from './Python3Icon';
import RabbitMQIcon from './RabbitMQIcon';
import ReactIcon from './ReactIcon';
import RedisIcon from './RedisIcon';
import S3Icon from './S3Icon';
import SketchIcon from './SketchIcon';
import SpriteKitIcon from './SpriteKitIcon';
import SwiftChartsIcon from './SwiftChartsIcon';
import SwiftIcon from './SwiftIcon';
import SwiftUIIcon from './SwiftUIIcon';
import UnityIcon from './UnityIcon';
import VercelIcon from './VercelIcon';
import VisualStudioIcon from './VisualStudioIcon';
import WPFIcon from './WPFIcon';
import WidgetKitIcon from './WidgetKitIcon';
import WindowsServerIcon from './WindowsServerIcon';
import XCTestIcon from './XCTestIcon';
// geology game icons
import OpenCVIcon from './OpenCVIcon';
import PillowIcon from './PillowIcon';
import TuriBoltIcon from './TuriBoltIcon';
import TuriTroveIcon from './TuriTroveIcon';
import EmeraldIcon from './geology_game/EmeraldIcon';
import FireOrbIcon from './geology_game/FireOrbIcon';
import FireShotIcon from './geology_game/FireShotIcon';
import GemIcon from './geology_game/GemIcon';
import HeartIcon from './geology_game/HeartIcon';
import RockIcon from './geology_game/RockIcon';
import RockOrbIcon from './geology_game/RockOrbIcon';


export default function Icon({ name }) {
  switch (name) {
    case 'bootstrap4':
    case 'bootstrap':
      return <Bootstrap4Icon />;
    case 'bootstrap3':
      return <Bootstrap3Icon />;
    case 'css':
      return <CSSIcon />;
    case 'vercel':
      return <VercelIcon />;
    case 'swiftUI':
      return <SwiftUIIcon />;
    case 'widgetKit':
      return <WidgetKitIcon />;
    case 'swiftCharts':
      return <SwiftChartsIcon />;
    case 'xctest':
      return <XCTestIcon />;
    case 'deviceActivity':
      return <DeviceActivityIcon />;
    case 'heroku':
      return <HerokuIcon />;
    case 'html':
      return <HTMLIcon />;
    case 'js':
    case 'javascript':
      return <JavaScriptIcon />;
    case 'react':
    case 'reactjs':
      return <ReactIcon />;
    case 'bolt':
      return <TuriBoltIcon />;
    case 'trove':
      return <TuriTroveIcon />;
    case 'pytorch':
      return <PyTorchIcon />;
    case 'bluetoothle':
      return <BluetoothLEIcon />;
    case 'bluetooth2':
    case 'bluetooth2.0':
    case 'bluetooth20':
      return <Bluetooth2Icon />;
    case 'swift':
      return <SwiftIcon />;
    case 'openCV':
      return <OpenCVIcon />;
    case 'objc':
      return <ObjectiveCIcon />;
    case 'c':
      return <CIcon />;
    case 'csharp':
    case 'c#':
      return <CSharpIcon />;
    case 'arduino':
      return <ArduinoIcon />;
    case 'avfoundation':
      return <AVFoundationIcon />;
    case 'ios11':
      return <IOS11Icon />;
    case 'handlebars':
      return <HandlebarsIcon />;
    case 'mongo':
    case 'mongodb':
      return <MongoDBIcon />;
    case 'nginx':
      return <NGINXIcon />;
    case 'windowsserver':
      return <WindowsServerIcon />;
    case 'mysql':
      return <MySQLIcon />;
    case 'visualstudio':
      return <VisualStudioIcon />;
    case 'mahapps':
      return <MahappsIcon />;
    case 'unity':
      return <UnityIcon />;
    case 'java':
      return <JavaIcon />;
    case 'python3':
      return <Python3Icon />;
    case 'kml':
      return <KMLIcon />;
    case 'spritekit':
      return <SpriteKitIcon />;
    case 'circuits':
      return <CircuitsIcon />;
    case 'php':
      return <PHPIcon />;
    case 'gamecenter':
      return <GameCenterIcon />;
    case 'pillow':
      return <PillowIcon />;
    case 'jquery':
      return <JqueryIcon />;
    case 'chartjs':
      return <ChartJSIcon />;
    case 'momentjs':
      return <MomentJSIcon />;
    case 'linux':
      return <LinuxIcon />;
    case 'node':
    case 'nodejs':
      return <NodeJSIcon />;
    case 'wpf':
      return <WPFIcon />;
    case 'sketch':
      return <SketchIcon />;
    case 'illustrator':
      return <IllustratorIcon />;
    // geology game icons
    case 'geoEmerald':
      return <EmeraldIcon />;
    case 'geoGem':
      return <GemIcon />;
    case 'geoFireOrb':
      return <FireOrbIcon />;
    case 'geoFireShot':
      return <FireShotIcon />;
    case 'geoHeart':
      return <HeartIcon />;
    case 'geoRock':
      return <RockIcon />;
    case 'geoRockOrb':
      return <RockOrbIcon />;
    case 'redis':
      return <RedisIcon />;
    case 's3':
      return <S3Icon />;
    case 'django':
      return <DjangoIcon />;
    case 'kubernetes':
      return <KubernetesIcon />;
    case 'postgres':
      return <PostgresIcon />;
    case 'rabbitmq':
      return <RabbitMQIcon />;
    case 'celery':
      return <CeleryIcon />;
    default:
      return <>{ name }</>;
  }
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};
