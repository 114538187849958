import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from '../BaseIcon';
import gemImage from '../../images/projects/2015_geology_game/05_gem.png';

function GemImage({ size, className }) {
  return <img src={gemImage} height={size} className={className} alt="gem icon" />;
}


export default function GemIcon() {
  return (
    <BaseIcon
      Component={GemImage}
    />
  );
}

GemImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

GemImage.defaultProps = {
  className: '',
};
