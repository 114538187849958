import React from 'react';
import PageTitle from '../general/PageTitle';

export default function Contact() {
  return (
    <>
      <PageTitle title="Contact" />
      <p>
        You can contact me by email anytime and by phone between the hours of 9AM-9PM PST.
      </p>
      <p>
        <a href="mailto:aaron.j.sletten@gmail.com">aaron.j.sletten@gmail.com</a>
      </p>
      <p>
        701-331-2962
      </p>
    </>
  );
}
