import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';
import icon from '../images/icons/swift_charts.png';

function SwiftChartsImage({ size, className }) {
  return <img src={icon} width={size} height={size} className={className} alt="Swift Charts icon" />;
}


export default function SwiftChartsIcon() {
  return (
    <BaseIcon
      Component={SwiftChartsImage}
      title="Swift Charts"
    />
  );
}

SwiftChartsImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

SwiftChartsImage.defaultProps = {
  className: '',
};
