import React from 'react';
import { Row, Col } from 'react-bootstrap';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import TaskTable from '../general/TaskTable';
import Figures from '../general/Figures';
import CenteredIconList from '../icons/CenteredIconList';

import image01 from '../images/projects/2015_geology_game/01_app_icon.png';
import image02 from '../images/projects/2015_geology_game/02_game_play.png';
import image03 from '../images/projects/2015_geology_game/03_game_play.gif';
import image11 from '../images/projects/2015_geology_game/11_rock_monster.gif';
import image12 from '../images/projects/2015_geology_game/12_fireball.gif';
import image13 from '../images/projects/2015_geology_game/13_spider.gif';


const tasks = [
  {
    message: 'World based platformer game',
    status: 'complete',
  }, {
    message: 'Educational content',
    status: 'complete',
  }, {
    message: 'Collect rocks, minerals, and gemstones ',
    status: 'complete',
  }, {
    message: 'Kill monsters',
    status: 'complete',
  }, {
    message: 'Developed storyline',
    status: 'partially',
  }, {
    message: 'Level up',
    status: 'partially',
  }, {
    message: 'Craft items',
    status: 'incomplete',
  },
];


export default function GeologyGamePage() {
  return (
    <>
      <PageTitle title="Geology Game" />
      <PageHeader2 title="Summary" />
      <p>
        The geology based game I created is a platformer style game with a player who finds himself
        stuck near the center of the earth. The player must work his way to the surface while
        collecting coins, rocks, minerals, gemstones, and monster parts. The game is designed to be
        fun to play, but it also sprinkles some educational geology content throughout.
      </p>
      <Figures figures={[{ image: image01, size: 'xs' }]} />

      <PageHeader2 title="Project Requirements" />
      <TaskTable tasks={tasks} />

      <PageHeader2 title="Approach" />
      <p>
        When beginning this project, I made a point to plan out the game more thoroughly than I had
        for other projects in the past. I had learned from previous experience that creating a game
        without a “finish” point or requirements in mind was difficult, so I began with writing up
        a paper containing high level requirements for my game. I then went into detail underneath
        each requirement. Utilizing this strategy allowed me to create a plan for building the base
        layer of the game, and then later I could focus more on the nonessential fluff.
      </p>

      <Figures figures={[{ image: image02 }, { image: image03 }]} />

      <PageHeader2 title="Design" />
      <p>
        This game was designed using Unity 3D and programmed in C#. The graphics were designed by
        myself and a friend from high school. The designs were first drawn on paper and then
        converted into digital vectors using Illustrator CC. Then, the designs were rasterized into
        pixel art using Photoshop CC.
      </p>

      <CenteredIconList iconNames={[
        'geoEmerald',
        'geoGem',
        'geoRockOrb',
        'geoRock',
        'geoFireOrb',
        'geoFireShot',
        'geoHeart',
      ]}
      />

      <Row className="mt-5">
        <Col md={4} xs={12} className="text-center mb-5">
          <img src={image11} height={100} className="" alt="rock monster" />
        </Col>
        <Col md={4} xs={12} className="text-center mb-5">
          <img src={image12} height={100} className="" alt="fireball" />
        </Col>
        <Col md={4} xs={12} className="text-center mb-5">
          <img src={image13} height={100} className="" alt="spider" />
        </Col>
      </Row>
      <br />
    </>
  );
}
