import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';
import icon from '../images/icons/swiftui.png';

function SwiftUIImage({ size, className }) {
  return <img src={icon} width={size} height={size} className={className} alt="SwiftUI icon" />;
}


export default function SwiftUIIcon() {
  return (
    <BaseIcon
      Component={SwiftUIImage}
      title="SwiftUI"
    />
  );
}

SwiftUIImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

SwiftUIImage.defaultProps = {
  className: '',
};
