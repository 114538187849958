import React from 'react';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import TaskTable from '../general/TaskTable';
import Figures from '../general/Figures';

import africanElephantImage from '../images/projects/2018_endangered_species_map/01_african_elephant.png';
import bengalTigerImage from '../images/projects/2018_endangered_species_map/02_bengal_tiger.png';
import easternLowlandGorillaImage from '../images/projects/2018_endangered_species_map/03_eastern_lowland_gorilla.png';
import htmlPlacemarkExampleImage from '../images/projects/2018_endangered_species_map/04_html_placemark_example.png';
import closeUpPlacemarksImage from '../images/projects/2018_endangered_species_map/05_close_up_placemarks.png';
import usTemperatureMapImage from '../images/projects/2018_endangered_species_map/06_us_temperature_map.png';
import californiaTemperatureMapImage from '../images/projects/2018_endangered_species_map/07_california_temperature_map.png';
import alaskaTemperatureMapImage from '../images/projects/2018_endangered_species_map/08_alaska_temperature_map.png';


const tasks = [
  {
    message: 'Use a GIS system to create a demonstration',
    status: 'complete',
  },
];

export default function EndangeredSpeciesMapPage() {
  return (
    <>
      <PageTitle title="Endangered Species Map" />
      <PageHeader2 title="Summary" />

      <p>
        For my Environmental Sustainability course project at NDSU, I wrote a Python script that created a KML file from
        endangered species data. The KML opens in Google Earth or Google Maps and contains coordinates, pictures, links,
        and other information about endangered species around the world.
      </p>


      <PageHeader2 title="Project Requirements" />
      <TaskTable tasks={tasks} />

      <PageHeader2 title="Python" />
      <p>
        To get the data I needed, I referenced WorldWildLife.org (WWF). WWF has a list of endangered species with
        comprehensive data about each animal. I parsed the data and generated a KML using some build in XML Python
        libraries. Images of the animals were then processed to make icons. The icons were color coded red for critically
        endangered, orange for endangered, and yellow for vulnerable.
      </p>

      <Figures figures={[
        { image: africanElephantImage },
        { image: bengalTigerImage },
        { image: easternLowlandGorillaImage },
      ]}
      />

      <PageHeader2 title="Google Earth" />
      <p>
        Google Earth, along with Google Maps, have the ability to open KML files. KML stands for Keyhole Markup Language
        after “Keyhole Inc,” a company developing an early version of Google Earth that was then bought by Google. KML is
        an XML based format which can contain a variety of GIS information.
      </p>
      <p>
        For my project, I took advantage of the “Placemark” element which can contain icons, coordinates, titles,
        descriptions, and even HTML. The Placemark was populated with an icon of the endangered animal and an HTML
        formatted pane for the information about the animal.
      </p>

      <Figures figures={[
        { image: htmlPlacemarkExampleImage },
        { image: closeUpPlacemarksImage },
      ]}
      />

      <p>
        I also created a temperature map using custom icons, NOAA weather data, and color interpolation using the same
        methods as described above.
      </p>

      <Figures figures={[
        { image: usTemperatureMapImage },
        { image: californiaTemperatureMapImage },
        { image: alaskaTemperatureMapImage },
      ]}
      />

      <PageHeader2 title="Endangered Species" />
      <p>
        To find out more about endangered species visit World Wildlife’s
        {' '}
        <a href="https://www.worldwildlife.org/species">species page</a>
      </p>

      <p>
        To find out how you can help visit World Wildlife’s
        {' '}
        <a href="https://gifts.worldwildlife.org/gift-center/">gift page</a>
      </p>
    </>
  );
}
