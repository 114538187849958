import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function KMLSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 899 899" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient cx="15.1019718%" cy="15.1025898%" fx="15.1019718%" fy="15.1025898%" r="99.3409296%" id="radialGradient-1">
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#FFFFFF" stopOpacity="0" offset="100%" />
        </radialGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Google_Earth_icon" fillRule="nonzero">
          <g id="Group">
            <path d="M309.990524,543.131963 C156.757759,340.450277 -0.561022535,494.465842 40.5053456,636.123416 C143.800085,861.990197 410.679126,961.373314 636.596101,858.101616 C714.466711,822.505187 780.55685,765.426408 827.100058,693.572785 C708.651091,804.488689 493.563436,785.951803 309.990524,543.131963 Z" id="Path" fill="#255FDB" />
            <path d="M446.980714,403.567978 C236.234615,83.9958381 0.000413723073,254.301492 0.000413723073,449.527674 C-0.0844442213,514.183882 13.8566749,578.089687 40.8627793,636.838325 C12.8212805,504.525899 164.879248,402.495716 306.466332,592.410719 C512.053712,868.16809 713.810012,819.655178 826.691677,694.185621 C862.944123,638.547287 886.35864,575.540883 895.237719,509.734604 L895.237719,510.602722 C846.764931,660.941672 632.851872,685.402373 446.980714,403.567978 Z" id="Path" fill="#4285F4" />
            <path d="M591.428071,260.58266 C434.926452,2.44312224 236.489953,25.6781512 102.155845,164.016464 C35.937535,244.417906 -0.185536591,345.377431 8.50006853e-05,449.527674 C14.047226,260.582861 242.61928,129.087416 449.483552,449.527674 C633.36295,734.732466 862.445882,660.431109 895.033328,511.419903 L895.033328,509.990086 C897.885176,489.694104 899.098084,469.201395 898.659816,448.710693 L898.659816,428.284161 C819.029918,485.273903 714.320689,463.264346 591.428071,260.58266 Z" id="Path" fill="#91BFFF" />
            <path d="M592.500773,306.542356 C735.82448,536.901931 859.381218,477.971609 898.966303,428.692652 C897.366075,394.259127 891.795765,360.125202 882.366093,326.968887 C815.556473,328.347567 799.977818,317.215248 734.2411,217.125445 C633.771332,63.2119527 507.201077,-40.7078751 286.035243,30.7847841 C214.377602,58.8841992 151.084552,104.814015 102.155845,164.220809 C254.111717,24.4015432 440.544901,62.3438352 592.500773,306.542356 Z" id="Path" fill="#C4E1FF" />
            <path d="M724.434138,236.939141 C789.966664,337.182153 829.858235,342.288785 882.366093,326.968887 C814.284795,87.8872266 565.236923,-50.7484798 326.102121,17.3176317 C312.542906,21.1769346 299.172638,25.6711325 286.035243,30.7849846 C483.60323,-31.515936 623.70903,82.9745115 724.434138,236.939141 Z" id="Path" fill="#F5F5F5" />
          </g>
          <g id="Group" fillOpacity="0.2">
            <path d="M449.483552,5.25182043 C696.729814,5.25182043 897.56164,204.889981 898.966303,452.08089 L898.966303,449.527674 C898.966303,201.340906 697.725694,0.145187598 449.483552,0.145187598 C201.241411,0.145187598 0.000802320393,201.340906 0.000802320393,449.527674 L0.000802320393,452.08089 C1.40526417,204.889981 202.237291,5.25182043 449.483552,5.25182043 Z" id="Path" fill="#FFFFFF" />
            <path d="M449.483552,893.803527 C202.237291,893.803527 1.40546475,694.165367 0.000802320393,446.974257 L0.000802320393,449.527473 C0.000802320393,697.714642 201.24101,898.90996 449.483552,898.90996 C697.726095,898.90996 898.966303,697.714642 898.966303,449.527674 L898.966303,446.974257 C897.56164,694.165367 696.729613,893.803527 449.483552,893.803527 Z" id="Path" fill="#1A237E" />
          </g>
          <circle id="Oval" fillOpacity="0.1" fill="url(#radialGradient-1)" cx="449.5" cy="449.5" r="449.5" />
        </g>
      </g>
    </svg>
  );
}

export default function KMLIcon() {
  return (
    <BaseIcon
      Component={KMLSVG}
      title="KML"
    />
  );
}

KMLSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

KMLSVG.defaultProps = {
  className: '',
};
