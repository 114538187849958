import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function Bootstrap4SVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 910 790" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path d="M425.048967,323.271911 C477.956211,359.383045 504.409833,377.438612 504.409833,377.438612 C504.409833,377.438612 568.182162,334.355065 568.182162,334.355065 C631.685123,288.077589 543.416484,241.590028 488.448801,279.902087 C488.448801,279.902087 467.315523,294.358695 425.048967,323.271911 Z" id="path-bootstrap-1" />
        <path d="M326.323745,257.02938 C374.155725,289.67646 398.071715,306 398.071715,306 C398.071715,306 455.726518,267.049353 455.726518,267.049353 C513.137793,225.211164 433.336553,183.183043 383.641786,217.819919 C383.641786,217.819919 364.535773,230.889739 326.323745,257.02938 Z" id="path-bootstrap-2" />
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group">
          <path d="M78.5209672,449.027695 L376.237503,257.659195 C422.351831,228.017476 481.523713,228.017476 527.63804,257.659195 L825.354576,449.027695 C853.22966,466.945454 861.301668,504.067888 843.383909,531.942972 C838.735229,539.175035 832.586639,545.323625 825.354576,549.972305 L527.63804,741.340805 C481.523713,770.982524 422.351831,770.982524 376.237503,741.340805 L78.5209672,549.972305 C50.645883,532.054546 42.5738758,494.932112 60.4916346,467.057028 C65.1403147,459.824965 71.2889049,453.676375 78.5209672,449.027695 Z" id="Path" fill="#351467" />
          <path d="M78.5209672,346.027695 L376.237503,154.659195 C422.351831,125.017476 481.523713,125.017476 527.63804,154.659195 L825.354576,346.027695 C853.22966,363.945454 861.301668,401.067888 843.383909,428.942972 C838.735229,436.175035 832.586639,442.323625 825.354576,446.972305 L527.63804,638.340805 C481.523713,667.982524 422.351831,667.982524 376.237503,638.340805 L78.5209672,446.972305 C50.645883,429.054546 42.5738758,391.932112 60.4916346,364.057028 C65.1403147,356.824965 71.2889049,350.676375 78.5209672,346.027695 Z" id="Path" fill="#5521A1" />
          <path d="M84.5209672,240.027695 L382.237503,48.6591946 C428.351831,19.0174763 487.523713,19.0174763 533.63804,48.6591946 L831.354576,240.027695 C859.22966,257.945454 867.301668,295.067888 849.383909,322.942972 C844.735229,330.175035 838.586639,336.323625 831.354576,340.972305 L533.63804,532.340805 C487.523713,561.982524 428.351831,561.982524 382.237503,532.340805 L84.5209672,340.972305 C56.645883,323.054546 48.5738758,285.932112 66.4916346,258.057028 C71.1403147,250.824965 77.2889049,244.676375 84.5209672,240.027695 Z" id="Path" fill="#7441C2" />
          <path d="M272.190868,259.037696 L501.767318,413.093357 C564.049366,370.95938 595.190389,349.892392 595.190389,349.892392 C650.892389,315.617509 639.969488,266.607381 581.848633,245.939726 C557.467548,237.474927 519.301459,239.614828 495.989092,253.54758 C538.288809,201.587689 444.963674,150.738383 369.980674,192.918709 C369.980674,192.918709 337.384072,214.958371 272.190868,259.037696 Z" id="Path-bootstrap-2" fill="#FFFFFF" />
          <g id="Path-bootstrap-3">
            <use fill="#D8D8D8" xlinkHref="#path-bootstrap-1" />
            <use fill="#7441C2" xlinkHref="#path-bootstrap-1" />
          </g>
          <g id="Path-bootstrap-3">
            <use fill="#D8D8D8" xlinkHref="#path-bootstrap-2" />
            <use fill="#7441C2" xlinkHref="#path-bootstrap-2" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function Bootstrap4Icon() {
  return (
    <BaseIcon
      Component={Bootstrap4SVG}
      title="Bootstrap 4"
    />
  );
}

Bootstrap4SVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Bootstrap4SVG.defaultProps = {
  className: '',
};
