import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function IllustratorSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 240 234" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Adobe_Illustrator_CC_icon" fillRule="nonzero">
          <g id="Outline_no_shadow" fill="#330000">
            <path d="M42.5,0 L197.5,0 C221,0 240,19 240,42.5 L240,191.5 C240,215 221,234 197.5,234 L42.5,234 C19,234 0,215 0,191.5 L0,42.5 C0,19 19,0 42.5,0 Z" id="Path" />
          </g>
          <g id="Ai" transform="translate(49.000000, 51.000000)" fill="#FF9A00">
            <path d="M67.3,89.4 L30.1,89.4 L22.5,112.9 C22.3,113.8 21.5,114.4 20.6,114.3 L1.8,114.3 C0.7,114.3 0.4,113.7 0.7,112.5 L32.9,19.8 C33.2,18.8 33.5,17.7 33.9,16.5 C34.3,14.4 34.5,12.2 34.5,10 C34.4,9.5 34.8,9 35.3,8.9 C35.4,8.9 35.5,8.9 35.6,8.9 L61.2,8.9 C62,8.9 62.4,9.2 62.5,9.7 L99,112.7 C99.3,113.8 99,114.3 98,114.3 L77.1,114.3 C76.4,114.4 75.7,113.9 75.5,113.2 L67.3,89.4 Z M35.9,69.1 L61.3,69.1 C60.7,67 59.9,64.5 59,61.9 C58.1,59.2 57.2,56.3 56.3,53.3 C55.3,50.2 54.4,47.2 53.4,44.1 C52.4,41 51.5,38.1 50.7,35.2 C49.9,32.4 49.2,29.8 48.5,27.4 L48.3,27.4 C47.4,31.7 46.3,36 44.9,40.3 C43.4,45.1 41.9,50.1 40.3,55.1 C38.9,60.2 37.4,64.9 35.9,69.1 L35.9,69.1 Z" id="Shape" />
            <path d="M120.8,26 C117.5,26.1 114.3,24.8 111.9,22.5 C109.6,20 108.4,16.7 108.5,13.3 C108.4,9.9 109.7,6.7 112.1,4.4 C114.5,2.1 117.7,0.9 121,0.9 C124.9,0.9 127.9,2.1 130.1,4.4 C132.3,6.8 133.5,10 133.4,13.3 C133.5,16.7 132.3,20 129.9,22.5 C127.6,24.9 124.2,26.2 120.8,26 Z M109.6,112.8 L109.6,35.8 C109.6,34.8 110,34.4 110.9,34.4 L130.7,34.4 C131.6,34.4 132,34.9 132,35.8 L132,112.8 C132,113.9 131.6,114.4 130.7,114.4 L111.1,114.4 C110.1,114.4 109.6,113.8 109.6,112.8 Z" id="Shape" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default function IllustratorIcon() {
  return (
    <BaseIcon
      Component={IllustratorSVG}
      title="Illustrator"
    />
  );
}

IllustratorSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

IllustratorSVG.defaultProps = {
  className: '',
};
