import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function HTMLSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 451 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="HTML5_Badge" fillRule="nonzero">
          <polyline id="Path" fill="#E34F26" points="41 460 0 0 451 0 410 460 225 512" />
          <polyline id="Path" fill="#EF652A" points="226 472 375 431 410 37 226 37" />
          <path d="M226,208 L151,208 L146,150 L226,150 L226,94 L225,94 L84,94 L85,109 L99,265 L226,265 L226,208 Z M226,355 L225,355 L162,338 L158,293 L128,293 L102,293 L109,382 L225,414 L226,414 L226,355 Z" id="Shape" fill="#EBEBEB" />
          <path d="M225,208 L225,265 L295,265 L288,338 L225,355 L225,414 L341,382 L342,372 L355,223 L357,208 L341,208 L225,208 Z M225,94 L225,129 L225,150 L225,150 L362,150 L362,150 L362,150 L363,138 L366,109 L367,94 L225,94 Z" id="Shape" fill="#FFFFFF" />
        </g>
      </g>
    </svg>
  );
}


export default function HTMLIcon() {
  return (
    <BaseIcon
      Component={HTMLSVG}
      title="HTML 5"
    />
  );
}

HTMLSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

HTMLSVG.defaultProps = {
  className: '',
};
