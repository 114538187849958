import PropTypes from 'prop-types';
import React from 'react';
import icon from '../images/icons/celery.png';
import BaseIcon from './BaseIcon';

function CeleryImage({ size, className }) {
  return <img src={icon} width={size} height={size} className={className} alt="Celery icon" />;
}


export default function CeleryIcon() {
  return (
    <BaseIcon
      Component={CeleryImage}
      title="Celery"
    />
  );
}

CeleryImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

CeleryImage.defaultProps = {
  className: '',
};
