import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from '../BaseIcon';
import heartImage from '../../images/projects/2015_geology_game/10_heart.png';

function HeartImage({ size, className }) {
  return <img src={heartImage} height={size} className={className} alt="heart icon" />;
}


export default function HeartIcon() {
  return (
    <BaseIcon
      Component={HeartImage}
    />
  );
}

HeartImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

HeartImage.defaultProps = {
  className: '',
};
