import React from 'react';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import TaskTable from '../general/TaskTable';
import Figures from '../general/Figures';

import image01 from '../images/projects/2017_php_database_website/01_tournament_details.png';
import image02 from '../images/projects/2017_php_database_website/02_sql.png';
import image03 from '../images/projects/2017_php_database_website/03_schema.png';
import image04 from '../images/projects/2017_php_database_website/04_php.png';
import image05 from '../images/projects/2017_php_database_website/05_coach_details.png';


const tasks = [
  {
    message: 'Use mySQL',
    status: 'complete',
  }, {
    message: 'Use five or more tables',
    status: 'complete',
  }, {
    message: 'Use PHP for the back-end implementation',
    status: 'complete',
  }, {
    message: 'Protect against SQL injection',
    status: 'complete',
  },
];

export default function PHPDatabaseWebsitePage() {
  return (
    <>
      <PageTitle title="PHP Database Website" />
      <PageHeader2 title="Summary" />
      <p>
        This project was for my Database course at NDSU. The instructions for the project were to use a mySQL database with
        five or more tables to create an interactive website. My group and I built a ping pong tournament scheduler website
        for our project.
      </p>

      <Figures figures={[{ image: image01, size: 'md' }]} />


      <PageHeader2 title="Project Requirements" />
      <TaskTable tasks={tasks} />

      <PageHeader2 title="SQL" />
      <p>
        The database schema was designed using Visio. The SQL
        {' '}
        <kbd>INSERT</kbd>
        {' '}
        commands were then generated using a custom Java program. I used our class list for the tournament participants and the NDSU faculty for the coaches. These lists were parsed from the NDSU website.
      </p>
      <Figures figures={[
        { image: image02 },
        { image: image03 },
      ]}
      />

      <PageHeader2 title="Back-end" />
      <p>The back-end was programmed using embedded PHP. The code protects against SQL injection before sending SQL queries to the mySQL database. When the data returns, the PHP code uses the data to generate HTML tables and buttons for navigation. </p>
      <Figures figures={[
        { image: image04 },
        { image: image05 },
      ]}
      />

      <PageHeader2 title="Front-end" />
      <p>The front-end of the website is plain HTML stylized with CSS3. The page has some base HTML, but most of it is generated after PHP database requests.</p>
    </>
  );
}
