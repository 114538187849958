import React, { useEffect, useState } from 'react';
import { CardDeck, Row } from 'react-bootstrap';
import PageTitle from '../general/PageTitle';
import ProjectCard from '../general/ProjectCard';
import geologyGameImage from '../images/projects/2015_geology_game/00_geology_game_card.png';
import parachutezImage from '../images/projects/2015_parachutez/00_parachutez_card.png';
import centroidCalculatorImage from '../images/projects/2016_centroid_calculator/00_centroid_calculator_card.png';
import tankGameImage from '../images/projects/2016_tank_game/00_tank_game_card.png';
import phpDatabaseWebsiteImage from '../images/projects/2017_php_database_website/00_php_database_website_card.png';
import quadcopterPositioningImage from '../images/projects/2017_quadcopter_positioning/00_quadcopter_positioning_card.png';
import computerControlledToyCarImage from '../images/projects/2018_computer_controlled_toy_car/00_computer_controlled_toy_car_card.jpg';
import internalDatabaseToolImage from '../images/projects/2018_database_tool/00_internal_database_tool_card.png';
import endangeredSpeciesMapImage from '../images/projects/2018_endangered_species_map/00_endangered_species_map_card.png';
import labMonitorImage from '../images/projects/2018_lab_monitor/00_lab_monitor_card.png';
import strawberryRobotImage from '../images/projects/2018_strawberry_robot/00_strawberry_robot_card.png';
import timestampOCRImage from '../images/projects/2024_timestamp_ocr/timestamp_ocr_card.png';

export default function Projects() {
  let finalCardDecks = null;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const cards = [
    {
      title: 'Timestamp OCR',
      description: 'Fine tuned machine learning model to read timestamps from old film photos',
      viewPath: '/projects/timestamp_ocr',
      iconNames: ['pytorch', 'pillow', 'openCV', 'bolt', 'trove'],
      image: timestampOCRImage,
      key: 'Timestamp OCR',
    },
    {
      title: 'Lab Monitor Website',
      description: 'Website used to monitor various environmental chambers at Appareo',
      viewPath: '/projects/lab_monitor',
      iconNames: ['nodejs', 'bootstrap3', 'handlebars', 'mongodb', 'nginx'],
      image: labMonitorImage,
      key: 'Lab Monitor Website',
    },
    {
      title: 'Strawberry Imaging Robot',
      description: 'Arduino and iOS app robot used to take thousands of images of strawberries',
      viewPath: '/projects/strawberry_imaging_robot',
      iconNames: ['bluetoothle', 'arduino', 'avfoundation', 'swift', 'c'],
      image: strawberryRobotImage,
      key: 'Strawberry Imaging Robot',
    },
    {
      title: 'Internal Database Tool',
      description: 'Application, built for Appareo, that is used to send files to a server while also logging data about the files',
      viewPath: '/projects/internal_database_tool',
      iconNames: ['mysql', 'visualstudio', 'windowsserver', 'csharp', 'mahapps'],
      image: internalDatabaseToolImage,
      key: 'Internal Database Tool',
    },
    {
      title: 'Quadcopter Positioning',
      description: 'Quadcopter that can figure out its position relative to floor and walls using minimal sensors',
      viewPath: '/projects/quadcopter_positioning',
      iconNames: ['bluetoothle', 'arduino', 'unity', 'csharp', 'c'],
      image: quadcopterPositioningImage,
      key: 'Quadcopter Positioning',
    },
    {
      title: 'Centroid Calculator',
      description: 'Program that used Google Earth KML files to calculate the centroid of a US State',
      viewPath: '/projects/centroid_calculator',
      iconNames: ['java', 'kml'],
      image: centroidCalculatorImage,
      key: 'Centroid Calculator',
    },
    {
      title: 'Endangered Species Map',
      description: 'Python script that pulls data from worldwildlife.org and creates an interactive Google Earth map',
      viewPath: '/projects/endangered_species_map',
      iconNames: ['python3', 'kml', 'html'],
      image: endangeredSpeciesMapImage,
      key: 'Endangered Species Map',
    },
    {
      title: 'PHP Database Website',
      description: 'Database project that allows viewing of a SQL database',
      viewPath: '/projects/php_database_website',
      iconNames: ['php', 'mysql', 'html', 'css'],
      image: phpDatabaseWebsiteImage,
      key: 'PHP Database Website',
    },
    {
      title: 'Computer Controlled RC Car',
      description: 'Application that used an arduino hacked into an RC remote to allow communication with a Mac running Unity 3D',
      viewPath: '/projects/computer_controlled_car',
      iconNames: ['arduino', 'unity', 'c', 'csharp'],
      image: computerControlledToyCarImage,
      key: 'Computer Controlled RC Car',
    },
    {
      title: 'Geology Game',
      description: 'Platformer game to help teach geology',
      viewPath: '/projects/geology_game',
      iconNames: ['unity', 'csharp'],
      image: geologyGameImage,
      key: 'Geology Game',
    },
    {
      title: 'Tank Game',
      description: 'An interesting take on a "brick breaker" style game',
      viewPath: '/projects/tank_game',
      iconNames: ['unity', 'csharp', 'sketch', 'illustrator'],
      image: tankGameImage,
      key: 'Tank Game',
    },
    {
      title: 'Parachutez',
      description: 'Parachutez was built with SpriteKit and Swift 1.0',
      viewPath: '/projects/parachutez',
      iconNames: ['swift', 'objc', 'spritekit', 'gamecenter'],
      image: parachutezImage,
      key: 'Parachutez',
    },
    // {
    //   title: 'Frankenstein PC',
    //   description: 'PC that was built using two power supplies connected together',
    //   viewPath: '/projects/frankenstein_pc',
    //   iconNames: ['circuits'],
    //   image: frankensteinPCImage,
    //   key: 'Frankenstein PC',
    // },
  ];

  function getCardDecks(cardList, rowCount) {
    // split card list into chunks with length == rowCount
    const chunkedCardList = new Array(
      Math.ceil(cardList.length / rowCount),
    ).fill().map(() => cardList.splice(0, rowCount));

    return (
      <>
        {chunkedCardList.map((rowCardList) => (
          <Row className="mb-4">
            <CardDeck>
              {rowCardList.map((cardData) => (
                <ProjectCard
                  title={cardData.title}
                  description={cardData.description}
                  viewPath={cardData.viewPath}
                  iconNames={cardData.iconNames}
                  image={cardData.image}
                  key={cardData.title}
                />
              ))}
            </CardDeck>
          </Row>
        ))}
      </>
    );
  }

  function handleResize() {
    setScreenWidth(window.innerWidth);

    return (
      <>
        <PageTitle title="Projects" />
        {finalCardDecks}
      </>
    );
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  if (screenWidth < 992) {
    finalCardDecks = cards.map((cardData) => (
      <Row className="mb-4 mr-1 ml-1">
        <ProjectCard
          title={cardData.title}
          description={cardData.description}
          viewPath={cardData.viewPath}
          iconNames={cardData.iconNames}
          image={cardData.image}
          key={cardData.title}
        />
      </Row>
    ));
  } else if (screenWidth < 1200) {
    finalCardDecks = getCardDecks(cards, 2);
  } else {
    finalCardDecks = getCardDecks(cards, 3);
  }

  return (
    <>
      <PageTitle title="Projects" />

      {finalCardDecks}
    </>
  );
}
