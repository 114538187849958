import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

export default function CenteredCol({ children }) {
  return (
    <Col className="d-flex justify-content-center">
      {children}
    </Col>
  );
}

CenteredCol.propTypes = {
  children: PropTypes.node.isRequired,
};
