import React from 'react';
import { FcElectronics } from 'react-icons/fc';
import BaseIcon from './BaseIcon';

export default function CircuitsIcon() {
  return (
    <BaseIcon
      Component={FcElectronics}
      title="Circuits"
    />
  );
}
