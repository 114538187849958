import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from '../BaseIcon';
import rockOrbImage from '../../images/projects/2015_geology_game/06_rock_orb.png';

function RockOrbImage({ size, className }) {
  return <img src={rockOrbImage} height={size} className={className} alt="rock orb icon" />;
}


export default function RockOrbIcon() {
  return (
    <BaseIcon
      Component={RockOrbImage}
    />
  );
}

RockOrbImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

RockOrbImage.defaultProps = {
  className: '',
};
