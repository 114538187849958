import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from '../BaseIcon';
import fireShotImage from '../../images/projects/2015_geology_game/09_fire_shot.png';

function FireShotImage({ size, className }) {
  return <img src={fireShotImage} height={size} className={className} alt="fire shot icon" />;
}


export default function FireShotIcon() {
  return (
    <BaseIcon
      Component={FireShotImage}
    />
  );
}

FireShotImage.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

FireShotImage.defaultProps = {
  className: '',
};
