import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function JavaSVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 347 462" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="java" fillRule="nonzero">
          <path d="M169.14441,241 C157.664795,222.444684 149.343227,207.037659 137.771406,178.068763 C118.223789,129.160685 256.624608,84.9577472 200.517414,0 C224.836678,58.6989186 113.175525,94.9561681 102.82543,143.841181 C93.316713,188.793712 169.098307,241 169.132884,241 L169.14441,241 Z" id="Path" fill="#F44336" />
          <path d="M172.12526,153.711995 C169.909426,182.712746 197.815093,198.210562 198.63449,219.381687 C199.303857,236.632233 181.946486,251 181.946486,251 C181.946486,251 213.418262,244.830854 223.251028,218.528384 C234.157089,189.28548 202.108273,169.290529 205.397402,145.916962 C208.455716,123.52354 275,82 275,82 C275,82 177.13397,88.4343614 172.12526,153.711995 L172.12526,153.711995 Z" id="Path" fill="#F44336" />
          <path d="M267.138817,242.835751 C287.429306,238.284669 304.503856,251.154437 304.503856,266.005942 C304.503856,299.407427 258,331 258,331 C258,331 330,322.450879 330,267.584419 C330,231.314024 294.659383,222.223382 267.138817,242.835751 L267.138817,242.835751 Z" id="Path" fill="#1565C0" />
          <path d="M232.605366,270.041598 C232.605366,270.041598 255.03678,253.956919 261,247.909173 C205.96734,259.679017 80.2890162,261.272366 80.2890162,251.049349 C80.2890162,241.640451 120.829665,232.010579 120.829665,232.010579 C120.829665,232.010579 31,230.696357 31,257.364591 C31,285.160963 148.588685,287.161372 232.605366,270.041598 Z" id="Path" fill="#1565C0" />
          <path d="M219.324122,295.496652 C167.130173,312.880701 70.9742852,307.317805 99.4679204,284 C85.574234,284 65,295.137381 65,305.903903 C65,327.413767 169.028977,344.032919 246,312.521431 L219.324122,295.496652 Z" id="Path" fill="#1565C0" />
          <path d="M112.263706,332 C93.2805138,332 81,344.142973 81,353.005617 C81,380.514343 194.156991,383.253705 239,355.342132 L210.507817,336.546421 C176.994422,351.164029 92.8746422,353.178266 112.25211,332 L112.263706,332 Z" id="Path" fill="#1565C0" />
          <path d="M316,376.399312 C316,365.530459 303.791804,360.408889 299.40195,357.975564 C325.185104,420.257103 40.8986145,415.413627 40.8986145,378.658828 C40.8986145,370.281237 61.8517704,362.135391 81.1717616,365.982362 L64.7474525,356.249061 C26.200132,350.235272 0,367.164263 0,380.837234 C0,444.61353 315.988417,441.531318 315.988417,376.399312 L316,376.399312 Z" id="Path" fill="#1565C0" />
          <path d="M347,400 C299.027745,447.047145 177.332144,464.192808 55,435.107237 C177.332144,486.865992 346.421324,457.745947 347,400 Z" id="Path" fill="#1565C0" />
        </g>
      </g>
    </svg>
  );
}

export default function JavaIcon() {
  return (
    <BaseIcon
      Component={JavaSVG}
      title="Java"
    />
  );
}

JavaSVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

JavaSVG.defaultProps = {
  className: '',
};
