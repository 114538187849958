import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function BaseIcon({ Component, title, color }) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={(<Tooltip>{title}</Tooltip>)}
    >
      <span>
        <Component style={{ color }} />
      </span>
    </OverlayTrigger>
  );
}

BaseIcon.propTypes = {
  Component: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

BaseIcon.defaultProps = {
  Component: null,
  color: 'black',
};
