import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function IOS11SVG({ size, className }) {
  return (
    <svg width={size} height={size}className={className} viewBox="0 0 194 194" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stopColor="#004655" offset="0%" />
          <stop stopColor="#17858B" offset="12.38%" />
          <stop stopColor="#2ABDB9" offset="30.576%" />
          <stop stopColor="#DAC0AB" offset="81.784%" />
          <stop stopColor="#E9BCA5" offset="100%" />
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="IOS_11_logo">
          <g id="a-link" fill="#FFFFFF">
            <rect id="a" x="0" y="0" width="194" height="194" rx="45" />
          </g>
          <path d="M64.127,160 L79.42,160 L79.42,33.17 L63.951,33.17 L38.895,50.996 L38.895,67.695 L63.775,49.957 L64.12656,49.957 L64.12656,159.997 L64.127,160 Z M126.232,160 L141.525,160 L141.525,33.17 L126.056,33.17 L101,50.996 L101,67.695 L125.88,49.957 L126.23156,49.957 L126.23156,159.997 L126.232,160 Z" id="Shape" fill="url(#linearGradient-1)" fillRule="nonzero" />
        </g>
      </g>
    </svg>
  );
}

export default function IOS11Icon() {
  return (
    <BaseIcon
      Component={IOS11SVG}
      title="iOS 11"
    />
  );
}

IOS11SVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

IOS11SVG.defaultProps = {
  className: '',
};
