import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import CenteredCol from './CenteredCol';


export default function Footnote({ footnoteTitle, footnote, citation }) {
  return (
    <Row className="mb-4">
      <Col xs={1} />
      <CenteredCol>
        <div className="text-muted">
          <span className="font-weight-bold">
            {footnoteTitle}
            :
            {' '}
          </span>
          &quot;
          {footnote}
          &quot;
          <span className="font-italic">
            {' '}
            -
            {citation}
          </span>
        </div>
        <br />
      </CenteredCol>
      <Col xs={1} />
    </Row>
  );
}

Footnote.propTypes = {
  footnoteTitle: PropTypes.string,
  footnote: PropTypes.string.isRequired,
  citation: PropTypes.string,
};

Footnote.defaultProps = {
  footnoteTitle: null,
  citation: null,
};
