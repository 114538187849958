import React from 'react';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import TaskTable from '../general/TaskTable';
import Figures from '../general/Figures';
import CenteredIconList from '../icons/CenteredIconList';

import image01 from '../images/projects/2016_tank_game/00_tank_game_card.png';
import image02 from '../images/projects/2016_tank_game/02_home_page.gif';
import image03 from '../images/projects/2016_tank_game/03_first_level.gif';
import image04 from '../images/projects/2016_tank_game/04_red_ball.gif';
import image05 from '../images/projects/2016_tank_game/05_stuck_balls.jpg';
import image06 from '../images/projects/2016_tank_game/06_cracked_balls.jpg';


const tasks = [
  {
    message: 'Can be played quickly',
    status: 'complete',
  }, {
    message: 'Is addictive',
    status: 'complete',
  }, {
    message: 'Original feel',
    status: 'complete',
  }, {
    message: 'Can be learned easily',
    status: 'complete',
  },
];


export default function TankGamePage() {
  return (
    <>
      <PageTitle title="Tank Game" />
      <PageHeader2 title="Summary" />
      <p>
        In this game, the player uses a paddle to catch balls and deliver them to the correct
        tank. This game is world based with ten levels in each of the six worlds.
      </p>

      <Figures figures={[{ image: image01, size: 'md' }]} />


      <PageHeader2 title="Project Requirements" />
      <TaskTable tasks={tasks} />

      <PageHeader2 title="Design" />
      <p>
        The design of the game is similar to the “Breakout” game; however, instead of bouncing
        balls off of a paddle, you have to catch them and then drop them into tanks. Catching
        the balls takes some practice though, because the chamber is circular, and, when the paddle
        is at the top of the screen, the controls feel backwards.
      </p>
      <Figures figures={[
        { image: image02, size: 'xs' },
      ]}
      />

      <p>
        After a ball is caught, the paddle turns the color of the ball. The player then has to go
        to the correct tank and drop the ball off before getting the next one. This process is
        fairly trivial until red balls are introduced. Red balls have the ability to knock the ball
        off of the paddle if touched.
      </p>
      <Figures figures={[
        { image: image03, size: 'sm' },
        { image: image04, size: 'sm' },
      ]}
      />
      <p>
        Other types of balls and game twists are introduced as the levels progress. Some examples
        are fast balls, shielded balls, slow-mo balls, death balls, and trapped balls. The fast
        balls travel faster than normal, and slo-mo balls slow down time. The shielded balls have
        a shell around them that has to broken off by contacting the paddle several times. The death
        balls end the game if they contact the paddle, but they move very slow. Lastly, the trapped
        balls are stuck in place until the player shoots them with a projectile that is released
        from the paddle.
      </p>

      <Figures figures={[
        { image: image05, size: 'sm' },
        { image: image06, size: 'sm' },
      ]}
      />

      <p>
        The game graphics were designed using Sketch 3 and Illustrator CC, and the game was
        programmed in Unity 3D using C#.
      </p>

      <CenteredIconList iconNames={['unity', 'csharp', 'sketch', 'illustrator']} />
    </>
  );
}
