import React from 'react';
import { DiUnitySmall } from 'react-icons/di';
import BaseIcon from './BaseIcon';

export default function UnityIcon() {
  return (
    <BaseIcon
      Component={DiUnitySmall}
      title="Unity"
    />
  );
}
