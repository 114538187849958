import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import TaskTable from '../general/TaskTable';
import Figures from '../general/Figures';
import CenteredIconList from '../icons/CenteredIconList';

import stepperMotorImage from '../images/projects/2018_strawberry_robot/01_stepper_motor.png';
import arduinoImage from '../images/projects/2018_strawberry_robot/02_arduino.png';
import iphoneAppImage from '../images/projects/2018_strawberry_robot/03_strawberry_iphone_app.png';
import strawberry00 from '../images/projects/2018_strawberry_robot/04_strawberries/00_strawberry/00_strawberry.JPG';
import strawberry01 from '../images/projects/2018_strawberry_robot/04_strawberries/00_strawberry/01_strawberry.JPG';
import strawberry02 from '../images/projects/2018_strawberry_robot/04_strawberries/00_strawberry/02_strawberry.JPG';
import strawberry03 from '../images/projects/2018_strawberry_robot/04_strawberries/00_strawberry/03_strawberry.JPG';
import strawberry10 from '../images/projects/2018_strawberry_robot/04_strawberries/01_strawberry/10_strawberry.JPG';
import strawberry11 from '../images/projects/2018_strawberry_robot/04_strawberries/01_strawberry/11_strawberry.JPG';
import strawberry12 from '../images/projects/2018_strawberry_robot/04_strawberries/01_strawberry/12_strawberry.JPG';
import strawberry13 from '../images/projects/2018_strawberry_robot/04_strawberries/01_strawberry/13_strawberry.JPG';
import strawberry20 from '../images/projects/2018_strawberry_robot/04_strawberries/02_strawberry/20_strawberry.JPG';
import strawberry21 from '../images/projects/2018_strawberry_robot/04_strawberries/02_strawberry/21_strawberry.JPG';
import strawberry22 from '../images/projects/2018_strawberry_robot/04_strawberries/02_strawberry/22_strawberry.JPG';
import strawberry23 from '../images/projects/2018_strawberry_robot/04_strawberries/02_strawberry/23_strawberry.JPG';

const tasks = [
  {
    message: 'Take pictures of 4 sides of a strawberry',
    status: 'complete',
  },
  {
    message: 'Images are well lit',
    status: 'complete',
  },
  {
    message: 'Images have adequate contrast',
    status: 'complete',
  },
  {
    message: 'Images have specified aspect ratio',
    status: 'complete',
  },
  {
    message: 'Images have specified resolution',
    status: 'complete',
  },
];

const codeString = `
void loop() {
  if(bluetoothSerial.available()){
    // read message from bluetooth module
    String message = bluetoothSerial.readString();
    // check for start
    if(message.equals("start")){
      for(int i = 0; i < numberOfPictures-1; i++){
        // rotate stepper
        myStepper.step(stepLength);
        // send take message
        bluetoothSerial.println("take");
        // wait for delayTime
        delay(delayTime);
      }
    }
  }
}
`;


export default function StrawberryImagingRobotPage() {
  return (
    <>
      <PageTitle title="Strawberry Imaging Robot" />
      <PageHeader2 title="Summary" />
      <p>
        The strawberry imaging robot is used to take pictures of strawberries for use in training a
        neural network. The robot consists of a stepper motor, an arduino, a bluetooth module, and
        an iPhone. The stepper motor turns the strawberries while the iPhone takes pictures of the
        strawberries automatically.
      </p>
      <CenteredIconList iconNames={['bluetoothle', 'arduino', 'avfoundation', 'ios11', 'swift', 'objc', 'c']} />


      <PageHeader2 title="Project Requirements" />
      <TaskTable tasks={tasks} />

      <PageHeader2 title="Arduino" />
      <p>
        The arduino in this project is the interface between the bluetooth module and the stepper
        motor. The code on the arduino waits for the “start” signal from the bluetooth module to
        begin. Then the arduino rotates the stepper motor one quarter turn. After the rotation is
        completed, the arduino sends a “take” message back to the bluetooth module. The arduino
        then waits for a delay time and repeats the process.
      </p>

      <Figures figures={[
        {
          message: 'Stepper Motor',
          image: stepperMotorImage,
        },
        {
          message: 'Arduino',
          image: arduinoImage,
        },
      ]}
      />

      <SyntaxHighlighter language="arduino" style={vs}>
        {codeString}
      </SyntaxHighlighter>

      <PageHeader2 title="iPhone" />
      <p>
        The iPhone in this project is used to take pictures and start the rotation routine on the
        arduino. The application running on the phone is written in Swift and uses Core Bluetooth
        and AVFoundation. Core Bluetooth is used to communicate with the bluetooth module connected
        to the arduino. When the bluetooth module sends a “take” command, the application uses
        AVFoundation to take a picture and save it to the iPhone’s photos.
      </p>

      <Figures figures={[
        {
          image: iphoneAppImage,
          size: 'xs',
        },
      ]}
      />

      <PageHeader2 title="Samples" />
      <Figures figures={[
        { image: strawberry00 },
        { image: strawberry01 },
        { image: strawberry02 },
        { image: strawberry03 },
      ]}
      />
      <Figures figures={[
        { image: strawberry10 },
        { image: strawberry11 },
        { image: strawberry12 },
        { image: strawberry13 },
      ]}
      />
      <Figures figures={[
        { image: strawberry20 },
        { image: strawberry21 },
        { image: strawberry22 },
        { image: strawberry23 },
      ]}
      />
    </>
  );
}
