import React from 'react';
import CenteredIconList from '../icons/CenteredIconList';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import TaskTable from '../general/TaskTable';
import Figures from '../general/Figures';

import wallDetectionImage from '../images/projects/2017_quadcopter_positioning/01_wall_detection.png';
import ultrasonicProblemsImage from '../images/projects/2017_quadcopter_positioning/02_ultrasonic_problems.png';
import integralsOfAccelerationImage from '../images/projects/2017_quadcopter_positioning/03_integrals_of_acceleration.png';
import wallAnchorImage from '../images/projects/2017_quadcopter_positioning/04_wall_anchor.png';
import controlInterfaceImage from '../images/projects/2017_quadcopter_positioning/05_control_interface.png';
import controlInterfaceGIF from '../images/projects/2017_quadcopter_positioning/06_control_interface.gif';
import quadcopterFloorImage from '../images/projects/2017_quadcopter_positioning/07_quadcopter_floor.jpg';
import arduinoImage from '../images/projects/2017_quadcopter_positioning/08_arduino.jpg';
import gallery09Image from '../images/projects/2017_quadcopter_positioning/09_gallery.jpg';
import gallery10Image from '../images/projects/2017_quadcopter_positioning/10_gallery.jpg';
import gallery11Image from '../images/projects/2017_quadcopter_positioning/11_gallery.jpg';
import gallery12Image from '../images/projects/2017_quadcopter_positioning/12_gallery.jpg';
import gallery13Image from '../images/projects/2017_quadcopter_positioning/13_gallery.png';
import gallery14Image from '../images/projects/2017_quadcopter_positioning/14_gallery.png';


const tasks = [
  {
    message: 'Calculate relative position in space',
    status: 'complete',
  },
  {
    message: 'Use low cost sensors and computing devices',
    status: 'complete',
  },
  {
    message: 'Complete in one semester',
    status: 'partially',
  },
  {
    message: 'Navigate using relative position',
    status: 'incomplete',
  },
];

export default function QuadcopterPositioningPage() {
  return (
    <>
      <PageTitle title="Quadcopter Positioning" />
      <PageHeader2 title="Summary" />
      <p>
        For my Robotics/AI class at North Dakota State University, I created a quadcopter positioning system. The goal of
        the project was to create a system that could use an array of ultrasonic sensors and an IMU to figure out its
        relative position in space.
      </p>

      <Figures figures={[{ image: wallDetectionImage, size: 'md' }]} />


      <PageHeader2 title="Project Requirements" />
      <TaskTable tasks={tasks} />

      <PageHeader2 title="Distance" />
      <p>
        Ultrasonic sensors were used for getting a rough idea about how far away an object was from the system. However,
        ultrasonic sensors posed a few issues for our purposes. The main issue was the existence of only a single point for
        each sensor instead of a point cloud (which are used for most other autonomous systems). The ability to only
        collect one point for each ultrasonic sensor gave the system a limited amount of spatial data, so assumptions had
        to be made about the environment.
      </p>

      <p>
        Another issue for the ultrasonic sensors were corners and odd angles. If an ultrasonic sensor is pointed at a
        corner, the sound wave will bounce off the walls and give inaccurate readings. Also, if an ultrasonic sensor is
        pointed at an edge, the sound wave will bounce off in different directions and not return to the sensor.
      </p>


      <Figures figures={[
        {
          image: ultrasonicProblemsImage,
          size: 'md',
        },
      ]}
      />


      <PageHeader2 title="Position" />
      <p>
        Positioning was achieved by using the ultrasonic sensor array in combination with an IMU. The IMU could produce
        gyroscopic and acceleration data. With a gyroscope, the roll, pitch, and yaw could be calculated. With the
        acceleration data, the position could be calculated using the double integral of the acceleration data.
      </p>
      <Figures figures={[
        {
          message: 'Intergrals of acceleration',
          image: integralsOfAccelerationImage,
          size: 'md',
        },
      ]}
      />

      <p>
        However, the acceleration data from the IMU tended to be inaccurate. To correct for the inaccuracy, the distances
        from the ultrasonic sensors were used to anchor the system’s position.
      </p>
      <Figures figures={[
        {
          message: 'Ultrasonic Anchor',
          image: wallAnchorImage,
          size: 'md',
        },
      ]}
      />

      <PageHeader2 title="Control System" />
      <p>
        The control system used onboard the quadcopter was an Arduino UNO. The arduino gathered data from the IMU and
        ultrasonic sensor array and transmitted it via a Bluetooth 2.0 module. The signal was then received by my laptop
        running a visualization program written in Unity 3D.
      </p>

      <CenteredIconList iconNames={['bluetooth2.0', 'arduino', 'unity', 'csharp', 'c']} />

      <Figures figures={[
        { image: controlInterfaceImage },
        { image: controlInterfaceGIF },
      ]}
      />

      <PageHeader2 title="Construction" />
      <p>
        Almost all of the parts for the system were salvaged together from other projects and kits. The quadcopter frame was
        3D printed over the course of two weeks with ABS plastic. After the parts were printed, they were fastened together
        using M4 bolts and nuts from an arduino car kit. The arduino, arduino shield, ultrasonic sensors, and jumper wires
        were also salvaged from multiple car kits. The motors, propellers, and speed controllers were purchased in a kit
        from Hobbymate, and an IMU was purchased from Knacro. Lastly, the battery and power distribution board were
        borrowed from a classmate.
      </p>

      <Figures figures={[
        { image: quadcopterFloorImage },
        { image: arduinoImage },
      ]}
      />

      <PageHeader2 title="Gallery" />
      <Figures figures={[
        { image: gallery09Image },
        { image: gallery10Image },
      ]}
      />
      <Figures figures={[
        { image: gallery11Image },
        { image: gallery12Image },
      ]}
      />
      <Figures figures={[
        { image: gallery13Image, message: 'IMU Data' },
        { image: gallery14Image, message: 'IMU Data' },
      ]}
      />
    </>
  );
}
