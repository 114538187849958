import React from 'react';
import PropTypes from 'prop-types';
import BaseIcon from './BaseIcon';


function Python3SVG({ size, className }) {
  return (
    <svg width={size} height={size} className={className} viewBox="0 0 111 110" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="13.1240191%" y1="11.6996305%" x2="79.5070766%" y2="78.4532626%" id="linearGradient-python3-1">
          <stop stopColor="#387EB8" offset="0%" />
          <stop stopColor="#366994" offset="100%" />
        </linearGradient>
        <linearGradient x1="19.2647686%" y1="20.315846%" x2="90.5604163%" y2="88.7730019%" id="linearGradient-python3-2">
          <stop stopColor="#FFE052" offset="0%" />
          <stop stopColor="#FFC331" offset="100%" />
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Python-logo-notext" fillRule="nonzero">
          <path d="M54.8125,0 C26.780768,0 28.53125,12.15625 28.53125,12.15625 L28.5625,24.75 L55.3125,24.75 L55.3125,28.53125 L17.9375,28.53125 C17.9375,28.53125 0,26.496974 0,54.78125 C0,83.06552 15.65625,82.0625 15.65625,82.0625 L25,82.0625 L25,68.9375 C25,68.9375 24.496348,53.28125 40.40625,53.28125 C56.31615,53.28125 66.9375,53.28125 66.9375,53.28125 C66.9375,53.28125 81.84375,53.52221 81.84375,38.875 C81.84375,24.227794 81.84375,14.65625 81.84375,14.65625 C81.84375,14.656248 84.10693,0 54.8125,0 Z M40.0625,8.46875 C42.723929,8.46875 44.875,10.619821 44.875,13.28125 C44.875,15.942679 42.723929,18.09375 40.0625,18.09375 C37.401071,18.09375 35.25,15.942679 35.25,13.28125 C35.25,10.619821 37.401071,8.46875 40.0625,8.46875 Z" id="path8615" fill="url(#linearGradient-python3-1)" />
          <path d="M55.6086,109.8461 C83.64034,109.8461 81.88985,97.68985 81.88985,97.68985 L81.8586,85.0961 L55.1086,85.0961 L55.1086,81.31485 L92.4836,81.31485 C92.4836,81.31485 110.4211,83.34912 110.4211,55.06484 C110.4211,26.780573 94.76485,27.783593 94.76485,27.783593 L85.4211,27.783593 L85.4211,40.90859 C85.4211,40.90859 85.92476,56.56484 70.01485,56.56484 C54.104948,56.56484 43.483598,56.56484 43.483598,56.56484 C43.483598,56.56484 28.577348,56.32388 28.577348,70.9711 C28.577348,85.6183 28.577348,95.18985 28.577348,95.18985 C28.577348,95.18985 26.314168,109.8461 55.6086,109.8461 Z M70.3586,101.37735 C67.69717,101.37735 65.5461,99.22628 65.5461,96.56485 C65.5461,93.90342 67.69717,91.75235 70.3586,91.75235 C73.02003,91.75235 75.1711,93.90342 75.1711,96.56485 C75.1711,99.22628 73.02003,101.37735 70.3586,101.37735 Z" id="path8620" fill="url(#linearGradient-python3-2)" />
        </g>
      </g>
    </svg>
  );
}

export default function Python3Icon() {
  return (
    <BaseIcon
      Component={Python3SVG}
      title="Python 3"
    />
  );
}

Python3SVG.propTypes = {
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Python3SVG.defaultProps = {
  className: '',
};
