import React from 'react';
import { Row, Col, Figure } from 'react-bootstrap';
import PageTitle from '../general/PageTitle';
import PageHeader2 from '../general/PageHeader2';
import Figures from '../general/Figures';

import image01 from '../images/projects/2015_parachutez/01_parachutez_icon.png';
import image02 from '../images/projects/2015_parachutez/02_game_over.jpg';
import image03 from '../images/projects/2015_parachutez/03_game_play_paused.jpg';
import image04 from '../images/projects/2015_parachutez/04_game_play_balloon.jpg';
import image05 from '../images/projects/2015_parachutez/05_home_page.png';
import image06 from '../images/projects/2015_parachutez/06_balloon_red.png';
import image07 from '../images/projects/2015_parachutez/07_balloon_green.png';
import image08 from '../images/projects/2015_parachutez/08_balloon_gold.png';
import image09 from '../images/projects/2015_parachutez/09_mountain_drawing.jpg';
import image10 from '../images/projects/2015_parachutez/10_mountain_background.png';
import image11 from '../images/projects/2015_parachutez/11_desert_drawing.jpg';
import image12 from '../images/projects/2015_parachutez/12_desert_background.png';
import CenteredCol from '../general/CenteredCol';

export default function ParachutezPage() {
  return (
    <>
      <PageTitle title="Parachutez" />
      <PageHeader2 title="Summary" />
      <p>
        After the game “Flappy Bird” by Dong Nguyen became so popular, I said to my friend, “We
        can do that.” So, we designed a similar game with the goals of it being extremely
        difficult to play yet still a very simple concept. The object of the game is to fall
        through thunder clouds without touching them for as long as you can while collecting
        power ups and other items.
      </p>

      <Figures figures={[{ image: image01, size: 'sm' }]} />

      <PageHeader2 title="Gameplay" />
      <p>
        In my game “Parachutez,” the player is falling through the clouds, trying to make it
        safely to the ground without coming into contact with the thunderclouds. Along the way,
        there are different colored balloons to collect to give you extra points. Among the
        balloons, there are also power ups including ghost and shield. Ghost lets you fall through
        the clouds without dying for a period of time, and the shield gives you protection for one
        cloud hit.
      </p>
      <Figures figures={[
        { image: image02 },
        { image: image03 },
        { image: image04 },
        { image: image05 },
      ]}
      />

      <PageHeader2 title="Design" />
      <p>
        This game was created using SpriteKit and Swift 1.0 and also integrated Game Center. The
        graphics were designed by myself and a couple of high school friends. The graphics were
        first drawn on paper and then vectorized using Sketch 3.
      </p>
      <Row>
        <Col sm={5} />
        <CenteredCol>
          <Figure>
            <Figure.Image alt="test" src={image06} />
          </Figure>
        </CenteredCol>
        <CenteredCol>
          <Figure>
            <Figure.Image alt="test" src={image07} />
          </Figure>
        </CenteredCol>
        <CenteredCol>
          <Figure>
            <Figure.Image alt="test" src={image08} />
          </Figure>
        </CenteredCol>
        <Col sm={5} />
      </Row>

      <PageHeader2 title="Gallery" />
      <Figures figures={[
        { image: image09 },
        { image: image10 },
        { image: image11 },
        { image: image12 },
      ]}
      />
    </>
  );
}
